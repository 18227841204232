// import { AlertTitle } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { toast } from "react-toastify";

import Loader from "../components/Loader";
import { __API_URL__ } from "../SERVER_URL";
import { AlertStatus } from "../servicemanager/enums";
import LockedAccountModal from "../components/PopUp/LockedAccountModal";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const tokenSaved = localStorage["token"];
  const storedOtpState = localStorage.getItem("otpState");
  const [isLogin, setIsLogin] = useState(
    tokenSaved == undefined ? false : ["otp_verification", "sending_otp", "password", "Remember_Device"].includes(storedOtpState) ? false : true);
  const [token, setToken] = useState(
    localStorage.getItem("token") == undefined
      ? null
      : localStorage.getItem("token")
  );
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loader,setLoader] = useState(false);
  const [alertOpen,setAlert] = useState({
    isOpen:false,
    type:AlertStatus.SUCCESS,
    msg:''
  });
  const [lockedAccount, setLockedAccount] = useState(false);

  const logout = useCallback(() => {
    const token =  localStorage.getItem("token");
    const user_data = JSON.parse(localStorage.getItem('user_data'));

    const endpoint = user_data.role === "Patient" ? "/api/patient/auth/logout/" : "/api/practitioners/logout/"

    axios
      .get(`${__API_URL__}${endpoint}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
      localStorage.removeItem("myKey");
      localStorage.removeItem("patientTableConfigurations");
      localStorage.clear()
      })
      .catch((err) => {
        console.log(err);
      });
    setToken(null);
    setIsLogin(false);
    setUser(null);
    setLoading(false);
    localStorage.removeItem("token");
    localStorage.removeItem("myKey");
    localStorage.clear()
    navigate("/login");
  }, []);

  const fetchUser = useCallback(
    (options) => {
      return new Promise((resolve, reject) => {
        const userURL = `${__API_URL__}/api/practitioners/personal-info/`;
        const userRequest = axios.get(userURL, options);

        const request = [userRequest];

        axios
          .all(request)
          .then(
            axios.spread((...responses) => {
              setUser(responses[0].data);
              // setIsLogin(false);
            setLoading(false);
              resolve(123);
          })
          )
          .catch((err) => {
            console.log(err);
            if (axios.isAxiosError(err)) {
              logout();
              resolve(123);
            }
            reject(err);
          });
      });
    },
    [logout]
  );

  const login = useCallback(
    (ld) => {
      setLoading(true);
      const endpoint = ld.isPatient ? "/api/patient/auth/login/" : "/api/practitioners/login/";
      const payload = ld.isPatient ? {email: ld.email, password: "123@Random.com", is_social: "1", versionCode: "3", versionName: "4"} : {email: ld.email, password: ld.password}
  
      return (
        axios.post(`${__API_URL__}${endpoint}`, payload)
        .then(async (res) => {
          setToken(res.data.token);
          localStorage.setItem("token", res.data.token);
  
            localStorage.setItem("user_data", JSON.stringify({
              "id": ld.isPatient ? res.data.patient_data.id : res.data.id,
              "full_name": ld.isPatient ? res.data.patient_data.display_name : res.data.full_name,
              "role": ld.isPatient ? "Patient" : res.data.role,
              "email": ld.isPatient ? res.data.patient_data.all_data.email : res.data.email,
              "owner_id": ld.isPatient ? null : res.data.owner_id
            }))
  
          const options = {
            headers: { Authorization: `Token ${res.data.token}` },
          };
  
          await fetchUser(options);
          return true;
        })
        .catch((err) => {
          if (err.response?.data?.is_locked) {
            setLockedAccount(true);
          } else {
            if (!err.response?.data?.is_locked) {
              toast.error("Invalid credentials. Please try again.");
            }
          }
          setLoading(false);
          return false;
          })
      );
    },
    [fetchUser]
  );

  const forgot_password = (email) =>{
    axios
    .post(`${__API_URL__}/api/auth/staff/forgot-password/`, {email:email})
      .then((res) => {
      toast.success("We have sent you an email with password reset link.")
    }).catch((err) => {
      toast.error(err.response.data.message)
      });
  }

  const reset_password = (newPass) =>{
    axios
      .post(`${__API_URL__}/api/auth/staff/change_password_from_email/`, newPass)
      .then((res) => {
        navigate("/login");
    }).catch((err) => {
      console.log(err)
      });
  }

  const setIsLoginAction = (state) =>{
    setIsLogin(state);
  }

  useLayoutEffect(() => {
    if (
      localStorage["token"] != undefined &&
      user == null
    ) {
      const options = {
        headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      };
      const getUser = async () => {
        setLoading(true);
        await fetchUser(options);
        setLoading(false);
      };
      getUser();
    }
  }, [fetchUser, user]);

  return (
    <UserContext.Provider
      value={{
        isLogin,
        actions: {
          login,
          forgot_password,
          reset_password,
          logout,
          setIsLoginAction,
        },
        loading,
        loader,
        setLoading:setLoader,
        customalert:{
          Input:alertOpen,
          setAlert:setAlert
        },
        state: {
          user,
          token,
        },
      }}
    >
      {isLogin ? (
        token == null || user == null ? (
          <Loader />
        ) : (
          
          children
        )
      ) : (
        children
      )}
      <LockedAccountModal
        open={lockedAccount}
        onClose={() => setLockedAccount(false)}
      />
    </UserContext.Provider>
  );
};

const useUser = () => useContext(UserContext);
export default useUser;
