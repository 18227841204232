import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const PopMessage = ({ setCheckPatient, addPatientApi }) => {
  return (
    <Modal
      size="md"
      show
      onHide={() => setCheckPatient(false)}
      style={{ zIndex: 9999, backdropFilter: 'blur(10px)' }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are You Sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <p className="text-center">
          Patient already exists in this Active Trial. Do you still want to
          add?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setCheckPatient(false);
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={() => addPatientApi()}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PopMessage;
