import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './hooks/user';
import { Utitlity } from './hooks/utility';
import { LoaderProvider } from './context/LoaderContext';

const app = (
  <BrowserRouter>
    <UserProvider>
      <Utitlity>
        <LoaderProvider>
          <App />
        </LoaderProvider>
      </Utitlity>
    </UserProvider>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
