import { createContext, useState } from 'react';

export const LoaderContext = createContext(0);

export const LoaderProvider = (props) => {
  const [loaderCount, setLoaderCount] = useState(0);
  
  return (
    <LoaderContext.Provider value={{ loaderCount, setLoaderCount }}>
      {props.children}
    </LoaderContext.Provider>
  );
};
