import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Select from 'react-select';
import axios from 'axios';

import { LoaderContext } from '../../context/LoaderContext';
import useUser from '../../hooks/user';
import { __API_URL__ } from '../../SERVER_URL';
import useFetch from '../../hooks/useFetch';

const AddStaff = ({
  setAddStaff,
  typeOfStaff,
  PaginationData,
  paginationurl,
  toast,
}) => {
  const loaderContext = useContext(LoaderContext);
  const { _id_ } = useParams();
  const { _type_ } = useParams();

  const [isUpdate, setIsUpdate] = useState(_id_ == undefined ? false : true);

  const get_endpoint =
    _type_ == 'practitioner'
      ? `practitioners/practitioner/${_id_}/`
      : isUpdate
      ? `clinicAdminView/${_id_}/`
      : null;

  const navigate = useNavigate();
  const user = useUser();
  const token = localStorage.getItem('token');
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const role = user_data.role;

  const [InfoData, setInfoData] = useState({
    first_name: '',
    last_name: '',
    email_custom: '',
    isAdmin: false,
    isPractitioner: false,
    clinic: '',
    name: '',
    head_user: '',
  });

  const [clinicList, setClinicList] = useState([]);
  const [headUsers, setHeadUsers] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfoData((InfoData) => ({
      ...InfoData,
      [name]: value,
    }));
  };

  const onClinicChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        newValue = clinicList.filter(
          (v) => v.value != actionMeta.removedValue.value
        );
        setInfoData((InfoData) => {
          return {
            ...InfoData,
            clinic: newValue,
          };
        });
        break;
      case 'pop-value':
        break;
      case 'clear':
        newValue = clinicList.filter((v) => v.isFixed);
        setInfoData((InfoData) => {
          return {
            ...InfoData,
            clinic: newValue,
          };
        });
        break;
      case 'select-option':
        setInfoData((InfoData) => {
          return {
            ...InfoData,
            clinic: newValue,
          };
        });
        break;
    }
  };

  const onHeadUserChange = (newValue, actionMeta) => {
    switch (actionMeta.action) {
      case 'remove-value':
        newValue = headUsers.filter(
          (v) => v.value != actionMeta.removedValue.value
        );
        setInfoData((InfoData) => {
          return {
            ...InfoData,
            head_user: newValue,
          };
        });
        break;
      case 'pop-value':
        break;
      case 'clear':
        newValue = headUsers.filter((v) => v.isFixed);
        setInfoData((InfoData) => {
          return {
            ...InfoData,
            head_user: newValue,
          };
        });
        break;
      case 'select-option':
        setInfoData((InfoData) => {
          return {
            ...InfoData,
            head_user: newValue,
          };
        });
        break;
    }
  };

  const ListOfClinics = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/clinic/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        const modifiedClinicList = res.data.map((clinic) => ({
          label: clinic?.name,
          value: clinic.id,
        }));
        setClinicList(modifiedClinicList);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const AddInstance = (e) => {
    e.preventDefault();

    let url;
    let isSubInvestigator = false;

    if (typeOfStaff === 'owner') {
      url = 'clinicOwnerCreate/';
    } else if (typeOfStaff === 'admin') {
      url = 'clinicAdminCreate/';
    } else if (typeOfStaff === 'cro_admin') {
      url = 'add_cro/';
    } else if (typeOfStaff === 'investigator') {
      url = 'add_investigator/';
    } else if (
      typeOfStaff === 'Cro_coordinator' ||
      typeOfStaff === 'Data Manager' ||
      typeOfStaff === 'Inv_coordinator' ||
      typeOfStaff === 'Sub_investigator'
    ) {
      url = 'patient/patient/add_coordinator/';
      isSubInvestigator = typeOfStaff === 'Sub_investigator';
    } else if (typeOfStaff === 'Reviewer') {
      url = 'practitioners/add_reviewer/';
    } else if (typeOfStaff === 'practitioner') {
      url = 'practitioners/practitioner/';
    }
    const CreateData = {
      ...InfoData,
      email: InfoData.email_custom,
      clinicName: '',
      clinicAddress: '',
      clinicPhone: '',
      clinic: InfoData?.clinic?.['value'],
      head_user: InfoData?.head_user?.['value'],
      typeOfStaff: typeOfStaff,
    };

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .post(`${__API_URL__}/api/${url}`, CreateData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })

      .then(() => {
        toast.success('Staff Added successfully');
        setAddStaff(false);
        PaginationData(paginationurl);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
        toast.error(err.response.data.message);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const AllHeadUsers = () => {
    let url;
    if (typeOfStaff === 'Cro_coordinator') {
      url = 'get_coordinator_croList';
    } else if (typeOfStaff === 'Data Manager') {
      url = 'get_coordinator_croList';
    } else if (typeOfStaff === 'Inv_coordinator') {
      url = 'add_investigator';
    } else if (typeOfStaff === 'Sub_investigator') {
      url = 'add_investigator';
    }

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/${url}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        const modifiedUserList = res.data.map((user) => ({
          label: user?.email,
          value: user.id,
        }));
        setHeadUsers(modifiedUserList);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const UpdateInfoStaff = (instance) => {
    const updateData = {
      first_name: instance.first_name,
      last_name: instance.last_name,
      name: instance.name,
      clinic_id: instance.clinic,
      isPractitioner: instance.isPractitioner,
    };
    axios
      .patch(`${__API_URL__}/api/${get_endpoint}`, updateData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      });
  };

  const UpdateInstance = (e, type) => {
    e.preventDefault();
    UpdateInfoStaff(InfoData);
    toast.success('Staff Updated Successfully!');
    setTimeout(() => {
      document.getElementById('cusMessages').style.right = '2rem';
    }, 100);
    setTimeout(() => {
      document.getElementById('cusMessages').style.right = '-99rem';
    }, 3000);
    setTimeout(() => navigate('/staff/'), 2000);
  };

  const handleHeadUser = (e) => {
    setInfoData((InfoData) => ({
      ...InfoData,
      head_user: e.target.value,
    }));
  };

  const [checkBoxPropsAdmin, setCheckBoxPropsAdmin] = useState({});

  const [checkBoxPropsPrac, setCheckBoxPropsPrac] = useState({});

  const { PatientData } = useFetch(get_endpoint);

  useEffect(() => {
    {
      PatientData &&
        setInfoData({
          ...InfoData,
          first_name: PatientData.first_name,
          last_name: PatientData.last_name,
          email_custom: PatientData.user.email,
          clinic:
            _type_ == 'practitioner'
              ? PatientData.practitioner_clinic[0]?.id
              : PatientData.clinics[0]?.id,
          name: PatientData.name ? PatientData.name : '',
          isAdmin: PatientData.isAdmin ? PatientData.isAdmin : false,
          isPractitioner: PatientData.isPractitioner
            ? PatientData.isPractitioner
            : false,
        });
    }

    PatientData.isAdmin || _type_ == 'admin'
      ? setCheckBoxPropsAdmin({
          checked: true,
          disabled: isUpdate && PatientData.isAdmin,
        })
      : setCheckBoxPropsAdmin({
          disabled: isUpdate && PatientData.isAdmin,
        });
    PatientData.isPractitioner || _type_ == 'practitioner'
      ? setCheckBoxPropsPrac({
          checked: true,
          disabled: isUpdate && PatientData.isPractitioner,
        })
      : setCheckBoxPropsPrac({
          disabled: isUpdate && PatientData.isPractitioner,
        });
  }, [PatientData]);

  // Captalize typeOfStaff
  let capitalized_name;
  if (typeOfStaff) {
    if (typeOfStaff === 'Inv_coordinator') {
      capitalized_name = 'Investigator coordinator';
    } else if (typeOfStaff === 'Cro_coordinator') {
      capitalized_name = 'CRO coordinator';
    } else if (typeOfStaff === 'Data Manager') {
      capitalized_name = 'Data Manager';
    } else if (typeOfStaff === 'admin') {
      capitalized_name = 'Clinic Admin';
    } else if (typeOfStaff === 'owner') {
      capitalized_name = 'Clinic Owner';
    } else if (typeOfStaff === 'practitioner') {
      capitalized_name = 'Clinic Practitioner';
    } else if (typeOfStaff === 'cro_admin') {
      capitalized_name = 'CRO Admin';
    } else if (typeOfStaff === 'Sub_investigator') {
      capitalized_name = 'Sub Investigator';
    } else {
      capitalized_name =
        typeOfStaff.charAt(0).toUpperCase() + typeOfStaff.slice(1);
    }
  }

  useEffect(() => {
    ListOfClinics();
  }, []);

  useEffect(() => {
    if (
      role === 'Super Admin' &&
      (typeOfStaff === 'Inv_coordinator' ||
        typeOfStaff === 'Sub_investigator' ||
        typeOfStaff === 'Cro_coordinator' ||
        typeOfStaff === 'Data Manager')
    ) {
      AllHeadUsers();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="container-fluid">
        <div className="patient_table info_form">
          <form
            onSubmit={
              isUpdate
                ? (e) => UpdateInstance(e, _type_)
                : (e) => AddInstance(e)
            }
          >
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-6">
                  {isUpdate ? (
                    <h5>Update Staff</h5>
                  ) : (
                    <h5>Add {capitalized_name}</h5>
                  )}
                </div>
                <div className="col-md-6 text-end">
                  <button type="submit" class="btn btn-primary m-2">
                    {loaderContext.loaderCount > 0 ? (
                      <i class="fa fa-spinner fa-spin"></i>
                    ) : isUpdate ? (
                      'Update'
                    ) : (
                      'Submit'
                    )}
                  </button>
                </div>
              </div>
            </div>
            {
              <>
                <div className="row" key="">
                  {isUpdate && _type_ == 'practitioner' ? (
                    <>
                      <div className="col-md-6">
                        <label>Name</label>
                        <input
                          required
                          type="text"
                          value={InfoData.name}
                          name="name"
                          onChange={handleChange}
                          className="form-control"
                        ></input>
                      </div>
                    </>
                  ) : (
                    isUpdate && (
                      <>
                        <div className="col-md-6">
                          <label>First Name</label>
                          <input
                            required
                            type="text"
                            value={InfoData.first_name}
                            name="first_name"
                            onChange={handleChange}
                            className="form-control"
                          ></input>
                        </div>
                        <div className="col-md-6">
                          <label>Last Name</label>
                          <input
                            required
                            type="text"
                            value={InfoData.last_name}
                            onChange={handleChange}
                            name="last_name"
                            className="form-control"
                          ></input>
                        </div>
                      </>
                    )
                  )}
                  {!isUpdate && (
                    <>
                      <div className="col-md-6">
                        <label>First Name</label>
                        <input
                          required
                          type="text"
                          value={InfoData.first_name}
                          name="first_name"
                          onChange={handleChange}
                          className="form-control"
                        ></input>
                      </div>
                      <div className="col-md-6">
                        <label>Last Name</label>
                        <input
                          required
                          type="text"
                          value={InfoData.last_name}
                          onChange={handleChange}
                          name="last_name"
                          className="form-control"
                        ></input>
                      </div>
                      <div className="col-md-6">
                        <label>Email Address</label>
                        <input
                          required
                          type="email"
                          value={InfoData.email_custom}
                          onChange={handleChange}
                          name="email_custom"
                          className="form-control"
                        ></input>
                      </div>
                      {/* <div className="col-md-6">
                        <label>Type</label>
                        <select className="form-control"
                        // value={clinicDetails.owner_details? clinicDetails.owner_details.id: ''}
                        onChange={handleChange}
                        name="type">
                            {user_data.role != 'Clinic Admin' && <>
                                <option value='admin'>
                                    Clinic Admin
                                </option>
                            </>}
                            <option value='practitioner'>
                                Practitioner
                            </option>
                        </select>
                    </div> */}
                    </>
                  )}

                  {(typeOfStaff === 'Inv_coordinator' ||
                    typeOfStaff === 'Cro_coordinator' ||
                    typeOfStaff === 'Data Manager' ||
                    typeOfStaff === 'Sub_investigator') &&
                    role === 'Super Admin' && (
                      <div className="col-md-6">
                        <label>
                          {typeOfStaff === 'Inv_coordinator' ||
                          typeOfStaff === 'Sub_investigator'
                            ? 'Investigator'
                            : 'CRO Admin'}
                        </label>
                        <Select
                          value={InfoData?.head_user}
                          className="basic-multi-select"
                          options={headUsers}
                          name="head_user"
                          onChange={onHeadUserChange}
                          required={true}
                        />
                      </div>
                    )}

                  {(typeOfStaff === 'admin' ||
                    typeOfStaff === 'practitioner') && (
                    <div className="col-md-6">
                      <label>Clinic</label>
                      <Select
                        value={InfoData.clinic}
                        className="basic-multi-select"
                        options={clinicList}
                        name="clinic"
                        onChange={onClinicChange}
                        required={true}
                      />
                    </div>
                  )}
                </div>
              </>
            }

            {(typeOfStaff === 'admin' ||
              typeOfStaff === 'practitioner' ||
              typeOfStaff === 'owner') && (
              <div className="col-md-6">
                <label style={{ 'margin-right': '1em' }}>Roles:</label>
                <div className="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="isAdmin"
                    value={InfoData.isAdmin}
                    {...checkBoxPropsAdmin}
                    id="flexCheckDefault1"
                    onChange={(e) => {
                      setInfoData({ ...InfoData, isAdmin: !InfoData.isAdmin });
                    }}
                  />
                  <label class="form-check-label" for="flexCheckDefault1">
                    Clinic Admin
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="isPractitioner"
                    value={InfoData.isPractitioner}
                    {...checkBoxPropsPrac}
                    id="flexCheckDefault2"
                    onChange={(e) => {
                      setInfoData({
                        ...InfoData,
                        isPractitioner: !InfoData.isPractitioner,
                      });
                    }}
                  />
                  <label class="form-check-label" for="flexCheckDefault2">
                    Clinic Practitioner
                  </label>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default AddStaff;
