import React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';

import * as XLSX from 'xlsx';

const CustomToolbar = ({
  reportName = 'Report',
  rows = [],
  columns = [],
  columnVisibilityModel = {},
}) => {
  const excludedFields = ['thumbnail']; //field names to exclude

  const exportToXlsx = () => {
    try {
      // Filter visible columns based on columnVisibilityModel and exclude specific fields
      const visibleColumns = columns.filter(
        (col) =>
          columnVisibilityModel[col.field] !== false &&
          !excludedFields.includes(col.field)
      );

      // Map rows to export only visible and allowed columns
      const dataToExport = rows.map((row) =>
        visibleColumns.reduce((acc, col) => {
          acc[col.headerName || col.field] =
            typeof row[col.field] === 'object' ? '' : row[col.field]; // Handle non-text data
          return acc;
        }, {})
      );

      // Create XLSX file
      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
      XLSX.writeFile(workbook, `${reportName}.xlsx`);
    } catch (error) {
      console.error('Failed to export XLSX:', error);
    }
  };

  return (
    <div>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {/* Custom XLSX Export Button */}
      <Button
        variant="text"
        color="primary"
        size="small"
        style={{
          marginLeft: 'auto',
          padding: '4px 8px',
          fontSize: '0.8rem',
          textTransform: 'none',
        }}
        onClick={exportToXlsx}
      >
        Export to XLSX
      </Button>
    </div>
  );
};
export default CustomToolbar;
