import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import Datetime from 'react-datetime';
import axios from 'axios';
import 'react-datetime/css/react-datetime.css';

import NavBar from '../../components/navBar/navBar';
import PatientMenu from './PatientMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { __API_URL__ } from '../../SERVER_URL';
import useFetch from '../../hooks/useFetch';

const UpdateAppointment = () => {
  const loaderContext = useContext(LoaderContext);
  const { _id_, UpdateId } = useParams();
  const token = localStorage.getItem('token');

  const [InfoData, setInfoData] = useState();
  const [RenderData, setRenderData] = useState('');
  const [ClinicList, setClinicList] = useState('');
  const [PractitionerList, setPractitionerList] = useState('');
  const { PatientData, UpdateAppointment } = useFetch(
    `appointments/update_appointment/${UpdateId}/`
  );
  const [dateTime, changeDateTime] = useState();
  const [treatmentTypes, setTreatmentTypes] = useState([]);
  const [treatmentAreasNeuro, setTreatmentAreasNeuro] = useState([]);
  const [treatmentAreasFillers, setTreatmentAreasFillers] = useState([]);
  const [treatmentDrugsNeuro, setTreatmentDrugsNeuro] = useState([]);
  const [treatmentDrugsFillers, setTreatmentDrugsFillers] = useState([]);

  const treatmentTypesApi = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(`${__API_URL__}/api/cms/treatment_types_old/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTreatmentTypes(res.data);
        setTreatmentDrugsNeuro(res.data[0].options);
        setTreatmentDrugsFillers(res.data[1].options);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const treatmentAreasApi = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(`${__API_URL__}/api/cms/treatment_areas_old/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTreatmentAreasNeuro(res.data.injectionList);
        setTreatmentAreasFillers(res.data.fillerList);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const [Appointment, setAppointmentData] = useState({
    duration: '',
    notes: '',
    status: '',
    practitioner: '',
    clinic: '',
    notifypush: false,
    notifysms: false,
    notifyemail: false,
    appointment_type_list: [],
  });

  var sampleAppoinment = {
    dosage: 0,
    dilution: 0,
    appointment_type: '',
    treatment_area: '',
    treatment_type: '',
    other_drugs: '',
  };

  useEffect(() => {
    PatientData.data && changeDateTime(new Date(PatientData.data.time + 'Z'));
    {
      PatientData.data &&
        setAppointmentData({
          ...Appointment,
          duration: PatientData.data.duration,
          notes: PatientData.data.notes,
          clinic: PatientData.data.clinic_dict.id,
          practitioner: PatientData.data.practitioner,
          status: PatientData.data.status,
          appointment_type_list:
            PatientData.data.appointment_type_list.length == 0
              ? [{ ...sampleAppoinment, appointment_type: 2 }]
              : PatientData.data.appointment_type_list,
        });
    }

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/appointments/appointment_dropdowns`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setRenderData(res.data.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/appointments/all_practitioner_clinics`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setClinicList(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });

    if (PatientData.data) {
      loaderContext.setLoaderCount((prev) => prev + 1);

      axios
        .get(
          `${__API_URL__}/api/appointments/all_practitioners?clinic=${PatientData.data?.clinic_dict.id}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          setPractitionerList(res.data);
        })
        .finally(() => {
          loaderContext.setLoaderCount((prev) => prev - 1);
        });
    }

    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  }, [PatientData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData((Appointment) => ({
      ...Appointment,
      [name]: value,
    }));
    if (name == 'clinic') onClinicChange(value);
  };

  const onClinicChange = (id) => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/appointments/all_practitioners?clinic=${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPractitionerList(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const handleList = (e) => {
    const { name, value } = e.target;
    let updatedObj = { [name]: value };
    if (name === 'appointment_type')
      updatedObj = { ...sampleAppoinment, ...updatedObj };
    setAppointmentData((Appointment) => ({
      ...Appointment,
      appointment_type_list: [
        ...Appointment.appointment_type_list.map((data, index) => {
          if (index == e.target.title) {
            return {
              ...data,
              ...updatedObj,
            };
          } else {
            return data;
          }
        }),
      ],
    }));
  };

  const UpdateInstance = (e) => {
    e.preventDefault();
    UpdateAppointment({
      ...Appointment,
      time: dateTime,
    });
  };

  const timeConstraints = {
    minutes: { step: 15 },
  };

  useEffect(() => {
    treatmentTypesApi();
    treatmentAreasApi();
  }, []);

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="Appointment Update"
          F={InfoData?.first_name}
          L={InfoData?.last_name}
          E={InfoData?.email}
          P={InfoData?.phone}
          participant_id={InfoData?.participant_id}
          age={InfoData?.age}
          birth_date={InfoData?.birth_date}
        />
        <div className="appointment_table">
          <PatientMenu active="appointments" data={InfoData} />
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <h5>Patient Appointment Update</h5>
              </div>
            </div>
          </div>
          {loaderContext.loaderCount > 0 ? (
            <Loader />
          ) : (
            <form className="UpdateAppointment" onSubmit={UpdateInstance}>
              <div className="row">
                <div className="col-md-3 calendarIcon">
                  <label>Select Time</label>
                  <i className="fa fa-calendar-o" aria-hidden="true"></i>
                  <Datetime
                    onChange={changeDateTime}
                    value={dateTime}
                    timeConstraints={timeConstraints}
                  />
                </div>
                <div className="col-md-2">
                  <label className="Nmin">Select Duration</label>
                  <input
                    type="number"
                    step="15"
                    value={Appointment.duration}
                    name="duration"
                    onChange={handleChange}
                    placeholder="0"
                    min="15"
                    max="60"
                    className="form-control"
                  />
                </div>
                <div className="col-md-3">
                  <label>Select Clinic</label>
                  <select
                    className="form-control"
                    name="clinic"
                    onChange={handleChange}
                    value={Appointment.clinic}
                    required
                  >
                    {loaderContext.loaderCount === 0 && ClinicList && (
                      <>
                        <option value="">Select</option>
                        {ClinicList.map((data) => (
                          <option value={data.id} key={data.id}>
                            {data.name}
                          </option>
                        ))}
                      </>
                    )}
                  </select>
                </div>
                <div className="col-md-2">
                  <label>
                    Select Practitioner <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select
                    className="form-control"
                    value={Appointment.practitioner}
                    name="practitioner"
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select</option>
                    {PractitionerList && (
                      <>
                        {PractitionerList.map((data) => (
                          <>
                            {data.practitioner_clinic[0]?.id != '' && (
                              <option value={data.id} key={data.id}>
                                {data.name}
                              </option>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  </select>
                </div>

                <div className="col-md-2">
                  <label>Select Status</label>
                  <select
                    className="form-control"
                    name="status"
                    onChange={handleChange}
                    value={Appointment.status}
                  >
                    {PatientData.data.status !== 'REQUESTED' && (
                      <>
                        <option value="COMPLETED">COMPLETED</option>
                      </>
                    )}
                    {PatientData.data.status !== 'COMPLETED' && (
                      <>
                        {' '}
                        (
                        {PatientData.data.status === 'REQUESTED' && (
                          <>
                            <option value="REQUESTED">REQUESTED</option>
                          </>
                        )}
                        <option value="UPCOMING">UPCOMING</option>
                        <option value="CANCELLED">CANCELLED</option>)
                      </>
                    )}
                  </select>
                </div>
                <div className="col-md-12">
                  <label>Enter Notes here</label>
                  <input
                    type="text"
                    value={Appointment.notes}
                    name="notes"
                    onChange={handleChange}
                    placeholder="Enter Notes here"
                    className="form-control"
                  />
                </div>
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-primary float-end mb-3"
                    onClick={() =>
                      setAppointmentData({
                        ...Appointment,
                        appointment_type_list: [
                          ...Appointment.appointment_type_list,
                          sampleAppoinment,
                        ],
                      })
                    }
                  >
                    Add Appointment Type
                  </button>
                </div>
                {Appointment.appointment_type_list.map(
                  (AppointmentType, index) => (
                    <div className="row" key={index}>
                      <div className="col-md-2">
                        <label>Appointment Type</label>
                        <select
                          className="form-control"
                          name="appointment_type"
                          value={AppointmentType.appointment_type}
                          title={index}
                          onChange={(e) => handleList(e)}
                        >
                          <option value="">Select</option>
                          {treatmentTypes.map((data) => (
                            <option value={data.id} key={data.id}>
                              {data.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      {Appointment.status == 'COMPLETED' &&
                        AppointmentType.appointment_type != 4 && (
                          <>
                            <div className="col-md-9 Neuromodulators">
                              <div className="row">
                                <div className="col-md-3">
                                  <label>Treatment Area</label>
                                  <select
                                    className="form-control"
                                    name="treatment_areas"
                                    title={index}
                                    value={AppointmentType.treatment_areas}
                                    defaultValue={
                                      AppointmentType.treatment_areas
                                    }
                                    onChange={(e) => handleList(e)}
                                  >
                                    <option>Select</option>
                                    {AppointmentType.appointment_type == 2 &&
                                      treatmentAreasNeuro.map((data) => (
                                        <option value={data.id} key={data.id}>
                                          {data.name}
                                        </option>
                                      ))}
                                    {AppointmentType.appointment_type == 3 &&
                                      treatmentAreasFillers.map((data) => (
                                        <option value={data.id} key={data.id}>
                                          {data.name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label>Treatment Type</label>
                                  <select
                                    className="form-control"
                                    name="treatment_type"
                                    title={index}
                                    defaultValue={
                                      AppointmentType.treatment_type
                                    }
                                    value={AppointmentType.treatment_type}
                                    onChange={(e) => handleList(e)}
                                  >
                                    <option>Select</option>
                                    {AppointmentType.appointment_type == 2 &&
                                      treatmentDrugsNeuro.map((data) => (
                                        <option value={data.id} key={data.id}>
                                          {data.title}
                                        </option>
                                      ))}
                                    {AppointmentType.appointment_type == 3 &&
                                      treatmentDrugsFillers.map((data) => (
                                        <option value={data.id} key={data.id}>
                                          {data.title}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <div className="col-md-3">
                                  <label>Dosage</label>
                                  <input
                                    type="number"
                                    onChange={handleList}
                                    title={index}
                                    name="dosage"
                                    value={AppointmentType.dosage}
                                    className="form-control"
                                  />
                                </div>
                                <div className="col-md-3">
                                  <label>Dilution</label>
                                  <input
                                    type="number"
                                    onChange={handleList}
                                    title={index}
                                    name="dilution"
                                    value={AppointmentType.dilution}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      <div className="col-md-1">
                        <span
                          onClick={() => {
                            setAppointmentData({
                              ...Appointment,
                              appointment_type_list:
                                Appointment.appointment_type_list.filter(
                                  ({ appointment_type_row_id }) =>
                                    appointment_type_row_id !=
                                    AppointmentType.appointment_type_row_id
                                ),
                            });
                          }}
                        >
                          <img
                            style={{
                              height: '20px',
                              marginTop: '2.2rem',
                              cursor: 'pointer',
                            }}
                            src="https://icon-library.com/images/delete_286553_12476.png"
                          />
                        </span>
                      </div>
                    </div>
                  )
                )}

                <div className="col-md-12">
                  <label>
                    <input
                      type="checkbox"
                      value={Appointment.notifyemail}
                      name="notifyemail"
                      onChange={() =>
                        setAppointmentData((Appointment) => ({
                          ...Appointment,
                          notifyemail: !Appointment.notifyemail,
                        }))
                      }
                    />
                    &nbsp;&nbsp;Notify Patient via Email
                  </label>
                </div>
                <div className="col-md-12">
                  <label>
                    <input
                      type="checkbox"
                      value={Appointment.notifypush}
                      name="notifypush"
                      onChange={() =>
                        setAppointmentData((Appointment) => ({
                          ...Appointment,
                          notifypush: !Appointment.notifypush,
                        }))
                      }
                    />
                    &nbsp;&nbsp;Notify Patient via Push
                  </label>
                </div>
                <div className="col-md-12">
                  <label>
                    <input
                      type="checkbox"
                      value={Appointment.notifysms}
                      name="notifysms"
                      onChange={() =>
                        setAppointmentData((Appointment) => ({
                          ...Appointment,
                          notifysms: !Appointment.notifysms,
                        }))
                      }
                    />
                    &nbsp;&nbsp;Notify Patient via SMS
                  </label>
                </div>
                <div className="col-md-12 text-center">
                  {(PatientData.data.status !== 'REQUESTED' ||
                    Appointment.status !== 'REQUESTED') && (
                    <>
                      <button className="btn btn-primary" type="submit">
                        Update
                      </button>
                    </>
                  )}
                  &nbsp;&nbsp;
                  <Link to={'/patient/' + _id_ + '/appointment/'}>
                    <button className="btn btn-danger">Close</button>
                  </Link>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateAppointment;
