import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { __API_URL__ } from '../SERVER_URL';
import useUser from '../hooks/user';
import { useContext } from 'react';
import { LoaderContext } from '../context/LoaderContext';

const useFetch = (query) => {
  const [PatientData, setPatientData] = useState('');
  const loaderContext = useContext(LoaderContext);
  const token = localStorage.getItem('token');
  const user = useUser();
  const [execute, setexecute] = useState(1);
  let navigate = useNavigate();
  const q = query;

  useEffect(() => {
    if (query) {
      loaderContext.setLoaderCount((prev) => prev + 1);

      axios
        .get(`${__API_URL__}/api/${q}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setPatientData(res.data);
        })
        .catch((err) => {
          if (err === 'Error: Request failed with status code 401') {
            user.actions.logout();
          }
        })
        .finally(() => {
          loaderContext.setLoaderCount((prev) => prev - 1);
        });
    }
  }, [query, execute]);

  const PaginationData = (q) => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(`${__API_URL__}${q}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPatientData(res.data);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const SearchData = (search) => {
    const params = `?page=${search.page}&page_size=${
      search.itemCount
    }&first_name=${search.fName}&last_name=${
      search.lName
    }&completed_photo_session_count=${
      search.completed_photo_session_count
    }&clinic=${search.clinic ? search.clinic : ''}&email=${
      search.email
    }&active=${search.deactive}&archived=${search.archived}&ordering=${
      search.ordering
    }&trial_name=${search.trial_name}&trial_status=${search.trial_status}`;

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/${q}${params}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPatientData(res.data);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const UpdateAppointment = (instance) => {
    const updateData = {
      duration: instance.duration,
      notes: instance.notes,
      practitioner: instance.practitioner,
      clinic: instance.clinic,
      status: instance.status,
      time: instance.time,
      appointment_type_list: instance.appointment_type_list,
      appointment_notify_push: instance.notifypush,
      appointment_notify_email: instance.notifyemail,
      appointment_notify_sms: instance.notifysms,
    };

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .patch(`${__API_URL__}/api/${query}`, updateData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        document.querySelector('.pactive a').click();
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '2rem';
        }, 2000);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '-99rem';
        }, 6000);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const CreateAppointment = (instance) => {
    const createData = {
      patient: instance.patient,
      appointment_duration: instance.duration,
      notes: instance.notes,
      appointment_time: instance.time,
      appointment_status: 'UPCOMING',
      appointment_clinic: instance.clinic,
      practitioner: instance.practitioner,
      appointment_notify_push: instance.notifypush,
      appointment_notify_email: instance.notifyemail,
      appointment_notify_sms: instance.notifysms,
      appointment_type: instance.type,
      existing_appointment_types: instance.appointment_type_list,
    };

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .post(`${__API_URL__}/api/appointments/create_appointment`, createData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        document.querySelector('.pactive a').click();
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '2rem';
        }, 2000);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '-99rem';
        }, 6000);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const UpdateInfo = (instance) => {
    const updateData = {
      firstName: instance.firstName,
      lastName: instance.lastName,
      phone: instance.phone,
      birth_date: instance.birth_date,
      gender: instance.gender,
      email: instance.email,
      skinTypeId: instance.skin_type,
    };

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .patch(`${__API_URL__}/api/${query}`, updateData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setexecute(execute + 1);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '2rem';
        }, 2000);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '-99rem';
        }, 6000);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const UpdateInfoOwner = (instance) => {
    // Should be removed once shifted to other page
    const updateData = {
      first_name: instance.first_name,
      last_name: instance.last_name,
      email: instance.email,
      isAdmin: instance.isAdmin,
      isPractitioner: instance.isPractitioner,
    };

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .patch(`${__API_URL__}/api/${query}`, updateData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {})
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev + 1);
      });
  };

  const CreateMessage = (instance) => {
    const MessageData = {
      patient_id: instance.patient,
      notification_title: instance.title,
      notification_message: instance.message,
      notification_types: [
        instance.typeApp,
        instance.typeSms,
        instance.typeEmail,
      ],
      notification_time: instance.time,
    };

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .post(`${__API_URL__}/api/patient/patient/messages/`, MessageData, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setexecute(execute + 1);
        document.getElementById('messageSend').style.display = 'none';
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '2rem';
        }, 2000);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '-99rem';
        }, 6000);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const DeleteMessage = (instance) => {
    axios
      .delete(`${__API_URL__}/api/${instance}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setexecute(execute + 1);
        document.getElementById('messageSend').style.display = 'none';
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const Sorting = (sort) => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/patient/patient/allpatient/${sort}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPatientData(res.data);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const Createreward = (instance) => {
    const createData = {
      type: instance.type,
      points: instance.points,
      reason: instance.reason,
    };

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .post(
        `${__API_URL__}/api/appiell/history_of_rewards/${instance.id}/`,
        createData,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        navigate(`/patient/${instance.id}/reward`);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '2rem';
        }, 2000);
        setTimeout(() => {
          document.getElementById('cusMessages').style.right = '-99rem';
        }, 6000);
      })
      .catch((err) => {
        if (err === 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const handleRefresh = () => {
    window.location.reload(true);
  };

  return {
    PatientData,
    SearchData,
    Sorting,
    PaginationData,
    UpdateAppointment,
    CreateAppointment,
    CreateMessage,
    DeleteMessage,
    UpdateInfo,
    handleRefresh,
    UpdateInfoOwner,
    Createreward,
  };
};

export default useFetch;
