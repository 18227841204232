import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import Datetime from 'react-datetime';
import axios from 'axios';
import 'react-datetime/css/react-datetime.css';

import NavBar from '../../components/navBar/navBar';
import PatientMenu from './PatientMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import { LoaderContext } from '../../context/LoaderContext';
import useFetch from '../../hooks/useFetch';
import { __API_URL__ } from '../../SERVER_URL';

const CreateAppointment = () => {
  const { _id_ } = useParams();
  const token = localStorage.getItem('token');
  const loaderContext = useContext(LoaderContext);

  const [showAddButton, setShowAddButton] = useState(true);
  const [PractitionerList, setPractitionerList] = useState('');
  const [InfoData, setInfoData] = useState();
  const [dateTime, changeDateTime] = useState();
  const [treatmentTypes, setTreatmentTypes] = useState([]);

  const { PatientData, CreateAppointment } = useFetch(
    'appointments/all_practitioner_clinics'
  );

  const treatmentTypesApi = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/cms/treatment_types_old/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setTreatmentTypes(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  useEffect(() => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/appointments/all_practitioners`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPractitionerList(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });

    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });

    treatmentTypesApi();
  }, []);

  const [Appointment, setAppointmentData] = useState({
    patient: _id_,
    duration: 30,
    notes: '',
    status: '',
    practitioner: '',
    clinic: '',
    notifypush: false,
    notifysms: false,
    notifyemail: false,
    type: '2',
    appointment_type_list: [{ app_type: 2 }],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData((Appointment) => ({
      ...Appointment,
      [name]: value,
    }));
    if (name == 'clinic') onClinicChange(value);
  };

  const onClinicChange = (id) => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/appointments/all_practitioners?clinic=${id}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPractitionerList(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const CreateInstance = (e) => {
    e.preventDefault();
    CreateAppointment({
      ...Appointment,
      time: new Date(dateTime).toISOString().split('.')[0],
    });
  };

  const timeConstraints = {
    minutes: { step: 15 },
  };
  const handleList = (e) => {
    const { name, value, title } = e.target;
    setAppointmentData((Appointment) => ({
      ...Appointment,
      appointment_type_list: [
        ...Appointment.appointment_type_list.map((data, index) => {
          if (index == title) {
            return {
              app_type: value,
            };
          } else {
            return data;
          }
        }),
      ],
    }));
  };
  const arrayOfTreatment = [
    { name: 'Select', value: '' },
    { name: 'Neuromodulators', value: 'Neuromodulators' },
    { name: 'Fillers', value: 'Fillers' },
    { name: 'Laser Contouring', value: 'Laser_Contouring' },
    { name: 'Others', value: 'Others' },
  ];

  const handleDelete = (id) => {
    const data = Appointment.appointment_type_list.filter(
      (item, index) => index !== id
    );
    setAppointmentData({
      ...Appointment,
      appointment_type_list: data,
    });
  };

  const AppointmentDataList = () => {
    setShowAddButton(true);
    setAppointmentData({
      ...Appointment,
      appointment_type_list: [
        ...Appointment.appointment_type_list,
        { app_type: '' },
      ],
    });
  };

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="Appointment Create"
          F={InfoData?.first_name}
          L={InfoData?.last_name}
          E={InfoData?.email}
          P={InfoData?.phone}
          participant_id={InfoData?.participant_id}
          age={InfoData?.age}
          birth_date={InfoData?.birth_date}
        />
        <div className="appointment_table">
          <PatientMenu active="appointments" data={InfoData} />
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <h5>Create Appointment</h5>
              </div>
            </div>
          </div>
          <form className="UpdateAppointment" onSubmit={CreateInstance}>
            <div className="row">
              <div className="col-md-3 calendarIcon">
                <label>
                  Select Time <span style={{ color: 'red' }}>*</span>
                </label>
                <i className="fa fa-calendar-o" aria-hidden="true"></i>
                <Datetime
                  onChange={changeDateTime}
                  value={dateTime}
                  timeConstraints={timeConstraints}
                  inputProps={{
                    placeholder: 'dd/mm/yy 00:00 AM',
                    required: 'required',
                  }}
                />
              </div>
              <div className="col-md-2">
                <label className="Nmin">
                  Select Duration <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="number"
                  step="15"
                  value={Appointment.duration}
                  name="duration"
                  onChange={handleChange}
                  placeholder="0"
                  required
                  min="15"
                  max="60"
                  className="form-control"
                />
              </div>
              <div className="col-md-3">
                <label>
                  Select Clinic <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  className="form-control"
                  value={Appointment.clinic}
                  name="clinic"
                  onChange={handleChange}
                  required
                  >
                  {loaderContext.loaderCount === 0 && (
                    <>
                  <option value="">Select</option>
                  {Array.isArray(PatientData) &&
                    PatientData.map((data) => (
                      <option value={data.id} key={data.id}>
                        {data.name}
                      </option>
                    ))}
                    </>
                  )}
                  </select>
              </div>
              <div className="col-md-2">
                <label>
                  Select Practitioner <span style={{ color: 'red' }}>*</span>
                </label>
                <select
                  className="form-control"
                  value={Appointment.practitioner}
                  name="practitioner"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select</option>
                  {PractitionerList && (
                    <>
                      {PractitionerList.map((data) => (
                        <>
                          {data.practitioner_clinic[0]?.id != '' && (
                            <option value={data.id} key={data.id}>
                              {data.name}
                            </option>
                          )}
                        </>
                      ))}
                    </>
                  )}
                </select>
              </div>

              <div className="col-md-2">
                <label>Select Status</label>
                <select
                  className="form-control"
                  value={Appointment.status}
                  name="status"
                  onChange={handleChange}
                >
                  <option value="UPCOMING">UPCOMING</option>
                </select>
              </div>
              <div className="col-md-12">
                <label>Enter Notes here</label>
                <input
                  type="text"
                  value={Appointment.notes}
                  name="notes"
                  onChange={handleChange}
                  placeholder="Enter Notes here"
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <button
                  type="button"
                  className="btn btn-primary float-end mb-3"
                  onClick={AppointmentDataList}
                >
                  Add Appointment Type
                </button>
              </div>
              {Appointment.appointment_type_list.map(
                (AppointmentType, index) => (
                  <div className="row" key={index}>
                    {showAddButton ? (
                      <div className="col-md-2">
                        <label>Appointment Type</label>
                        <select
                          className="form-control"
                          name="app_type"
                          value={AppointmentType.app_type}
                          defaultValue={AppointmentType.app_type}
                          title={index}
                          onChange={handleList}
                        >
                          <option value="">Select Option</option>
                          {treatmentTypes.map((data) => (
                            <option value={data.id} key={data.id}>
                              {data.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    ) : null}

                    <div className="col-md-1">
                      <span onClick={() => handleDelete(index)}>
                        <img
                          style={{
                            height: '20px',
                            marginTop: '2.2rem',
                            cursor: 'pointer',
                          }}
                          alt="img"
                          src="https://icon-library.com/images/delete_286553_12476.png"
                        />
                      </span>
                    </div>
                  </div>
                )
              )}
              {/* <div className="col-md-2">
                <label>
                  Treatment Type <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className="form-control"
                  value={Appointment.type}
                  name="type"
                  required
                  onChange={handleChange}
                >
                  <option value="2">Neuromodulators</option>
                  <option value="3">Fillers</option>
                  <option value="4">Device</option>
                </select>
              </div> */}
              {/* <div className="col-md-2 conditional">
                  <label>Treatment Area</label>
                  <select className="form-control">
                    <option>Neuromodulators</option>
                    <option value="filter">Fillers</option>
                    <option>Device</option>
                  </select>
                </div>
                <div className="col-md-2 conditional">
                  <label>Treatment Type</label>
                  <select className="form-control">
                    <option value="2">Neuromodulators</option>
                    <option value="3">Fillers</option>
                    <option value="4">Device</option>
                  </select>
                </div> */}
              <div className="col-md-12">
                <label>
                  <input
                    type="checkbox"
                    value={Appointment.notifyemail}
                    name="notifyemail"
                    onChange={() =>
                      setAppointmentData((Appointment) => ({
                        ...Appointment,
                        notifyemail: !Appointment.notifyemail,
                      }))
                    }
                  />
                  &nbsp;&nbsp;Notify Patient via Email
                </label>
              </div>
              <div className="col-md-12">
                <label>
                  <input
                    type="checkbox"
                    value={Appointment.notifypush}
                    name="notifypush"
                    onChange={() =>
                      setAppointmentData((Appointment) => ({
                        ...Appointment,
                        notifypush: !Appointment.notifypush,
                      }))
                    }
                  />
                  &nbsp;&nbsp;Notify Patient via Push
                </label>
              </div>
              <div className="col-md-12">
                <label>
                  <input
                    type="checkbox"
                    value={Appointment.notifysms}
                    name="notifysms"
                    onChange={() =>
                      setAppointmentData((Appointment) => ({
                        ...Appointment,
                        notifysms: !Appointment.notifysms,
                      }))
                    }
                  />
                  &nbsp;&nbsp;Notify Patient via SMS
                </label>
              </div>
              <div className="col-md-12 text-center">
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
                &nbsp;&nbsp;
                <Link to={'/patient/' + _id_ + '/appointment/'}>
                  <button className="btn btn-danger">Close</button>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateAppointment;
