import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import GoogleMapReact from 'google-map-react';

import NavBar from '../../components/navBar/navBar';
import PatientMenu from './PatientMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import MessageBox from '../../components/Alert/MessageBox';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import useFetch from '../../hooks/useFetch';

const PatientInfo = () => {
  const { _id_ } = useParams();
  const loaderContext = useContext(LoaderContext);

  const { PatientData, UpdateInfo } = useFetch(
    `patient/patient/singlepatient/?patientId=${_id_}`
  );

  const AnyReactComponent = () => (
    <div>
      <img
        style={{ height: '35px' }}
        src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png"
      />
    </div>
  );
  const [InfoData, setInfoData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    birth_date: '',
    gender: '',
    email: '',
    skin_type: '',
    participant_id: '',
  });

  useEffect(() => {
    {
      PatientData &&
        setInfoData({
          ...InfoData,
          firstName: PatientData.first_name,
          lastName: PatientData.last_name,
          phone: PatientData.phone,
          birth_date: PatientData.appiell_patient[0].age,
          gender: PatientData.appiell_patient[0].gender,
          email: PatientData.email,
          skin_type: PatientData.appiell_patient[0].skin_type?.id,
          participant_id: PatientData?.participant_id,
        });
    }
  }, [PatientData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInfoData((InfoData) => ({
      ...InfoData,
      [name]: value,
    }));
  };

  const UpdateInstance = (e) => {
    e.preventDefault();
    UpdateInfo(InfoData);
  };
  return (
    <>
      <MessageBox msg="Patient Data Updated!" />
      <NavBar active="patient" />
      <div className="container-fluid">
        {loaderContext.loaderCount === 0 && (
          <Breadcrumbs
            B="Personal Info"
            F={InfoData.firstName}
            L={InfoData.lastName}
            E={InfoData.email}
            P={InfoData.phone}
            participant_id={InfoData?.participant_id}
            age={PatientData?.age}
            birth_date={PatientData?.birth_date}
          />
        )}
        <div className="patient_table info_form">
          <PatientMenu active="info" data={PatientData} />
          <form onSubmit={UpdateInstance}>
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-6">
                  <h5>Patient Info</h5>
                </div>
                <div className="col-md-6 text-end">
                  <button type="submit" class="btn btn-primary btn-sm m-2">
                    Update
                  </button>
                </div>
              </div>
            </div>
            {loaderContext.loaderCount > 0 ? (
              <Loader />
            ) : (
              <>
                <div className="row" key={PatientData.id}>
                  <div className="col-md-6">
                    <label>First Name</label>
                    <input
                      type="text"
                      value={InfoData.firstName}
                      name="firstName"
                      onChange={handleChange}
                      className="form-control"
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label>Last Name</label>
                    <input
                      type="text"
                      value={InfoData.lastName}
                      onChange={handleChange}
                      name="lastName"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label>Email Address</label>
                    <input
                      type="email"
                      value={InfoData.email}
                      onChange={handleChange}
                      name="email"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      value={InfoData.phone}
                      onChange={handleChange}
                      name="phone"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label>Gender</label>
                    <select
                      className="form-control"
                      value={InfoData.gender}
                      onChange={handleChange}
                      name="gender"
                    >
                      <option value="">Select</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Birth Year</label>
                    <input
                      type="number"
                      value={InfoData.birth_date}
                      onChange={handleChange}
                      name="birth_date"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="col-md-6">
                    <label>Skin Type</label>
                    <select
                      className="form-control"
                      onChange={handleChange}
                      name="skin_type"
                      value={InfoData?.skin_type}
                    >
                      <option value="">select</option>
                      <option value="1">1 - Pale white skin</option>
                      <option value="2">
                        2 - Fair white skin, light or dark hair
                      </option>
                      <option value="3">
                        3 - Darker white skin, darker shades hair
                      </option>
                      <option value="4">
                        4 - Light brown skin, dark hair{' '}
                      </option>
                      <option value="5">5 - Brown skin, dark hair</option>
                      <option value="6">
                        6 - Dark brown or black skin, dark hair
                      </option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Device</label>
                    <input
                      type="text"
                      value={PatientData.appiell_patient && PatientData.appiell_patient[0]?.device || ''}
                      className="form-control"
                      disabled
                    ></input>
                  </div>
                </div>
                <div class="Experience-table">
                  <label>Experience</label>
                  <table>
                    <thead>
                      <tr>
                        <th>Treatment Type</th>
                        <th>Drug</th>
                        <th>Area</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PatientData?.experience?.map((data) => (
                        <tr>
                          <td>{data.treatments}</td>

                          <td>{data.treatment.title}</td>

                          <td>{data.area.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label>Location</label>
                    <input
                      type="text"
                      value={PatientData.location_dict?.Address}
                      className="form-control"
                    ></input>
                    {PatientData.location_dict && (
                      <>
                        <div
                          style={{
                            height: '300px',
                            width: '100%',
                            borderRadius: '8px',
                            overflow: 'hidden',
                          }}
                        >
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: 'AIzaSyBykNn93LjLI2MEv2PGOnSfpD_11zi1q30',
                              language: 'en',
                            }}
                            defaultCenter={{
                              lat: parseFloat(
                                PatientData.location_dict
                                  ? PatientData.location_dict.Latitude
                                  : 37.679878
                              ),
                              lng: parseFloat(
                                PatientData.location_dict
                                  ? PatientData.location_dict.Logitude
                                  : -95.459778
                              ),
                            }}
                            defaultZoom={13}
                          >
                            <AnyReactComponent
                              lat={parseFloat(
                                PatientData.location_dict
                                  ? PatientData.location_dict.Latitude
                                  : 37.679878
                              )}
                              lng={parseFloat(
                                PatientData.location_dict
                                  ? PatientData.location_dict.Logitude
                                  : -95.459778
                              )}
                            />
                          </GoogleMapReact>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default PatientInfo;
