import React from "react";
import NavBar from "../../components/navBar/navBar";
import PatientMenu from "./PatientMenu";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/breadcrumbs";
import { useNavigate, useParams } from "react-router";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { useEffect, useState } from "react";


const PatientExternalMedia = () => {
  const { _id_ } = useParams();
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const [Loader, setLoader] = useState(false);
  const role = user_data.role;
  const [visitData, setVisitData] = useState([]);
  const [infoData, setInfoData] = useState({});
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const SinglePatient = () => {
    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      });
  };

  const getVisitData = () => {
    setLoader(true);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/all/get_visits_data/?patient_id=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setVisitData(res.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err == "Error: Request failed with status code 401") {
        }
      });
  };

  useEffect(() => {
    SinglePatient();
    getVisitData();
  }, []);

  const routeTo = (id) => {
    navigate("/patient/" + _id_ + "/sessions_external_media/" + id);
  };

  
  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="External Media - Session View"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />

        <div className="patient_table patient_photos">
          <PatientMenu active="externalMedia" data={infoData} />
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <Link to={"/patient/" + _id_ + "/sessions_external_media"}>
                  <button type="button" class="btn btn-primary">
                    Session View
                  </button>
                </Link>
                <Link to={"/patient/" + _id_ + "/sessions_external_media_gallery"}>
                  <button type="button" class="btn">
                    Gallery View
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {Loader ? (
            <div className="loader">
              <img src="https://i.gifer.com/Yb3A.gif" alt="" />
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  {(role === "Investigator" ||
                    role === "Super Admin" ||
                    role === 'Sub_investigator' ||
                    role === 'CRO Admin' ||
                    role === 'Cro_coordinator' ||
                    role === 'Data Manager' ||
                    role === "Inv_coordinator") && (
                    <>
                    {
                       (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ?
                         <th>Participant Id</th>
                         :
                         <th>User Email</th>
                    }
                     
                      
                      <th>Visit Number</th>
                      <th>{user_data.role === 'CRO Admin' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || role === 'Cro_coordinator' || role === 'Data Manager ' || role === "Super Admin" ? "Subject Start Date" : "Patient Start Date"}</th>
                      <th>{user_data.role === 'CRO Admin' || user_data.role === 'Investigator' || user_data.role === 'Sub_investigator' || user_data.role === 'Inv_coordinator' || role === 'Cro_coordinator' || role === 'Data Manager' || role === "Super Admin" ? "Subject Completed Date" : "Patient Completed Date"}</th>
                      <th>Number of Images</th>
                      <th>Number of Videos</th>
                      <th></th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {visitData?.map((data) => (
                  <>
                    {data?.visit_session?.map((visit) => 
                    {
                      return visit?.visit_type !== 'Unscheduled Event' &&
                      <tr key={visit?.id}>
                        {(role === "Investigator" ||
                        role === 'Sub_investigator' ||
                        role === 'CRO Admin' ||
                        role === "Super Admin" ||
                        role === 'Cro_coordinator' ||
                        role === "Inv_coordinator") && (
                        <>
                          <td>
                            { (role === 'CRO Admin' || role === 'Cro_coordinator' || role === 'Data Manager') ? infoData?.participant_id ? infoData?.participant_id : "-" :
                            infoData?.email ? infoData?.email : "-" }
                          </td>
                          <td>
                          <td>
                          {visit.visit_name 
                            ? visit.visit_name 
                            : visit.visit_type 
                              ? visit.visit_type 
                              : `Visit ${visit.visit_number}`}
                        </td>
                          </td> 
                          {visit?.start_date && !visit?.is_skipped ? (
                            <td
                              style={{
                                textAlignLast: "left",
                                textAlign: "center",
                              }}
                            >
                              {new Date(visit?.start_date + "Z").toDateString()}
                              ,&nbsp;
                              {new Date(
                                visit?.start_date + "Z"
                              ).toLocaleTimeString()}
                            </td>
                          ) : (
                            <td
                              style={{
                                textAlignLast: "center",
                                textAlign: "center",
                              }}
                            >
                              -
                            </td>
                          )}
                          {visit?.is_skipped ? (
                            <td
                              style={{
                                textAlignLast: "center",
                                textAlign: "center",
                              }}
                            >
                              -
                            </td>
                          ) : visit?.completed_date ? (
                            <td
                              style={{
                                textAlignLast: "left",
                                textAlign: "center",
                              }}
                            >
                              {new Date(
                                visit?.completed_date + "Z"
                              ).toDateString()}
                              ,&nbsp;
                              {new Date(
                                visit?.completed_date + "Z"
                              ).toLocaleTimeString()}
                            </td>
                          ) : (
                            <td
                              style={{
                                textAlignLast: "center",
                                textAlign: "center",
                              }}
                            >
                              -
                            </td>
                          )}
                          <td>
                            {visit.external_photo_video_count ? (visit.external_photo_video_count.image_count !== undefined ? visit.external_photo_video_count.image_count : 0) : "-"}
                          </td>
                          <td>
                            {visit.external_photo_video_count ? (visit.external_photo_video_count.video_count !== undefined ? visit.external_photo_video_count.video_count : 0) : "-"}
                          </td>
                          <td>
                            <button
                              type="button"
                              class="btn btn-primary btn-sm m-2"
                              onClick={visit?.id ? (e) => routeTo(visit?.id) : null}
                            >
                              View/Upload
                            </button>
                          </td>
                        </>
                        )}
                      </tr>
                })}
                  </>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default PatientExternalMedia;
