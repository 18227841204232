import { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import CheckSharpIcon from '@mui/icons-material/CheckSharp';
import QuestionMarkSharpIcon from '@mui/icons-material/QuestionMarkSharp';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddTrialTypePopUp from '../PopUp/AddTrialtypePopUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Badge from '@mui/material/Badge';

import MainAccordion from '../Accordion/accordion';
import Informed_Consent from '../Trials/Informed_Consent';
import TargetLesionImprovementAssessment from '../Trials/TargetLesionImprovementAssessment';
import Photographic_App_Before_Injection from '../Trials/Photographic_App_Before_Injection';
import Photographic_App_After_Injection from '../Trials/Photographic_App_After_Injection';
import Participant_Assessments_Pre_Injection from '../Trials/Participant_Assessments_Pre_Injection';
import Target_Lesion_Injection_Pain from '../Trials/Target_Lesion_Injection_Pain';
import Concomitant_Medication from '../Trials/Concomitant_Medication';
import Adverse_Event from '../Trials/Adverse_Event';
import Medical_surgical from '../Trials/Medical_Surgical_Worksheet';
import Informed_Consent_Stem_Cell from '../Trials/Informed_Consent_Stem_Cell';
import Demographics_Information_Stem_Cell from '../Trials/Demographics_Information_Stem_Cell';
import Inclusion_Criteria_Stem_Cell from '../Trials/Inclusion_Criteria_Stem_Cell';
import Exclusion_Criteria_Stem_Cell from '../Trials/Exclusion_Criteria_Stem_Cell';
import Skin_Biopsy_Stem_Cell from '../Trials/Skin_Biopsy_Stem_Cell';
import Visit_Comments_Stem_Cell from '../Trials/Visit_Comments_Stem_Cell';
import Sutures_Removed_Stem_Cell from '../Trials/Sutures_Removed_Stem_Cell';
import Fitzpatrick_Skin_Type from '../Trials/Fitzpatrick_Skin_Type';
import Global_Severity_Score_General from '../Trials/Global_Severity_Score_General';
import Child_Bearing_Potential from '../Trials/Child_Bearing_Potential';
import PhotoGraphic_App from '../Trials/PhotoGraphic_App';
import Dermatologic_Assessment from '../Trials/Dermatologic_Assessment';
import Exit_Form from '../Trials/Exit_Form';
import Participant_Contact_Info from '../Trials/ParticpantContactInfo';
import Site_Patient_Identification from '../Trials/SitePatientIdentification';
import Birth_Control from '../Trials/Birth_Control';
import Demographics from '../Trials/Demographics';
import Target_Lesion_Identification from '../Trials/Target_Lesion_Identification_Form';
import Inclusion_Criteria from '../Trials/Inclusion_Criteria_Form';
import Exclusion_Criteria from '../Trials/Exclusion_Criteria';
import Global_Severity_Score from '../Trials/Global_Severity_Score';
import Target_Lesion_Erythema_Assessment from '../Trials/Target_Lesion_Erythema_Assessment';
import Target_Lesion_Severity_Assessment from '../Trials/Target_Lesion_Severity_Assessment';
import Target_Lesion_Photography_Pre_Injection from '../Trials/Target_Lesion_Photography_Pre_Injection';
import Target_Lesion_Videography from '../Trials/Target_Lesion_Videography';
import Intralesional_Injection from '../Trials/Intralesional_Injections';
import Target_Lesion_Photography_Post_Injection from '../Trials/Target_Lesion_Photography_Post_Injection';
import Reminders from '../Trials/Reminders';
import PARTICIPANT_ASSESSMENTS from '../Trials/PARTICIPANT_ASSESSMENTS';
import Medical_History_Concomitant_Medication_Treatment from '../Trials/Medical History & Concomitant Medication & Treatment';
import EcrfBlocks from '../Trials/EcrfBlocks';

const AllBlocksAccordion = ({
  role,
  patientId,
  selectedVisit,
  handleTrialTypeChange,
  selectedBlock,
  setSelectedBlock,
  localBlocks,
  handleSelectBlock,
  handleBlockChange,
  surveyFormBool,
  handleBoolOperation,
  setSurveyFormOption,
  handleVisitFieldChange,
  surveyFormOption,
  allSurveys,
  setModalShow,
  consentFormBool,
  setConsentFormOption,
  trialId,
  disable,
  consentFormOption,
  photoTypes,
  allConsents,
  handlePhotoTypeChange,
  handleVisitUpdate,
  setHasChanged,
  hasChanged,
  infoData,
  setMessage,
  patientDetailApi,
  setSelectedVisit,
  setTypeOfMessage,
  AllVisitsData,
  modalVisitChangeShow,
  setModalVisitChangeShow,
  selectedSessionId,
  setSelectedSessionId,
  setAddNewSurvey,
  newForm,
  setNewForm,
  handleInstruction,
  searchTrailType,
  setSearchTrailType,
  searchBlockName,
  setSearchBlockName,
  trialDetailApi,
  trial,
}) => {
  const [instructionDisable, setInstructionDisable] = useState(false);
  const [showTrialTypeModal, setShowTrialTypeModal] = useState(false);
  const [blockId, setBlockId] = useState('');
  const [trialTypeIds, setTrialTypeIds] = useState([]);
  const [selectAll, setSelectAll] = useState(
    selectedVisit?.extra_photos.length > 0
  );

  useEffect(() => {
    setSelectAll(selectedVisit?.extra_photos.length > 0);
  }, [selectedVisit]);

  const [expandedTrialTypes, setExpandedTrialTypes] = useState({});

  const handleTrialTypeDisplay = (typesName) => {
    setExpandedTrialTypes((prev) => ({
      ...prev,
      [typesName]: !prev[typesName],
    }));
  };

  const handleSelectAll = () => {
    setHasChanged(true);
    if (selectAll) {
      setSelectedVisit((photo_type) => {
        return {
          ...photo_type,
          extra_photos: [],
        };
      });
    } else {
      setSelectedVisit((photo_type) => {
        return {
          ...photo_type,
          extra_photos: photoTypes,
        };
      });
    }
  };

  useEffect(() => {
    if (
      (selectedVisit.visit_number === -1 ||
        selectedVisit.visit_number === -2) &&
      (role === 'Investigator' ||
        role === 'Inv_coordinator' ||
        role === 'Sub_investigator')
    ) {
      setInstructionDisable(true);
    } else {
      setInstructionDisable(false);
    }
  }, [selectedVisit]);

  return (
    <>
      <MainAccordion
        title="Trial Blocks"
        isOpen={true}
        data={
          <>
            {(role === 'CRO Admin' ||
              role === 'Cro_coordinator' ||
              role === 'Data Manager' ||
              role === 'Super Admin') &&
              patientId === undefined && (
                <>
                  <h6 className="mx-2 my-2">
                    Select eCRF blocks to build Source Document
                  </h6>
                  <div
                    className="col-md-3 mx-2"
                    style={{
                      textAlign: 'left',
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  >
                    <input
                      type="text"
                      className="form-control"
                      name="trial_type"
                      value={searchTrailType}
                      onChange={(e) => setSearchTrailType(e.target.value)}
                      placeholder="Search by trial type"
                    />
                    <input
                      type="text"
                      className="form-control"
                      name="trial_name"
                      value={searchBlockName}
                      onChange={(e) => setSearchBlockName(e.target.value)}
                      placeholder="Search by block name"
                    />
                  </div>
                </>
              )}
            <div className="row">
              <div className="col-md-3">
                <div className="clinicCard borderless table-wrapper-scroll-y my-custom-scrollbar2">
                  <div className="row align-items-center">
                    <div className="col ml--2">
                      <a
                        style={{
                          cursor: 'pointer',
                          color:
                            selectedBlock === 'Patient Activity in this Visit'
                              ? 'black'
                              : 'var(--bs-link-color)',
                        }}
                        onClick={() => {
                          setSelectedBlock('Patient Activity in this Visit');
                          setNewForm(false);
                        }}
                      >
                        Patient Activity in this Visit
                      </a>
                    </div>
                    {role !== 'Investigator' &&
                      role !== 'Inv_coordinator' &&
                      role !== 'Reviewer' && (
                        <>
                          <div className="col-auto">
                            <input
                              type="checkbox"
                              checked={true}
                              disabled={true}
                              class="form-check-input"
                            />
                          </div>
                        </>
                      )}
                  </div>

                  {selectedVisit.trial_block?.map((block) => {
                    return (
                      ((role === 'Inv_coordinator' &&
                        block.roles?.find((role) => role.name === 'CRC')) ||
                        role !== 'Inv_coordinator') && (
                        <div className="row align-items-center">
                          <div className="col ml--2">
                            <a
                              key={block.id}
                              style={{
                                cursor: 'pointer',
                                color:
                                  selectedBlock === block.name
                                    ? 'black'
                                    : block.is_completed
                                    ? 'green'
                                    : block.roles?.find(
                                        (role) => role.name === 'INV'
                                      )
                                    ? 'orange'
                                    : 'red',
                              }}
                              onClick={() =>
                                handleSelectBlock(block.name, block.new_block)
                              }
                            >
                              {block.is_completed && (
                                <CheckSharpIcon
                                  style={{
                                    color:
                                      block.is_completed &&
                                      !block.possible_incorrect_data &&
                                      !block.has_constraint_error
                                        ? 'green'
                                        : 'blue',
                                    marginRight: '5px',
                                  }}
                                />
                              )}
                              {block.has_constraint_error && (
                                <QuestionMarkSharpIcon
                                  style={{ color: 'red', marginRight: '5px' }}
                                />
                              )}
                              {block.possible_incorrect_data && (
                                <PriorityHighIcon
                                  style={{ color: 'red', marginRight: '5px' }}
                                />
                              )}
                              {block.name}
                              <br />
                              {role === 'CRO Admin' && (
                                <AddCircleOutlineIcon
                                  onClick={() => {
                                    setShowTrialTypeModal(true);
                                  }}
                                />
                              )}
                              <br />
                              <div
                                style={{ fontSize: 'small', color: 'black' }}
                              >
                                [
                                {block.trial_type.map((blockObject, index) => {
                                  if (
                                    index < 2 ||
                                    expandedTrialTypes[block.name]
                                  ) {
                                    return (
                                      <span
                                        key={index}
                                        onClick={() =>
                                          handleTrialTypeDisplay(block.name)
                                        }
                                        style={{ cursor: 'pointer' }}
                                      >
                                        {blockObject.title || blockObject.label}
                                        {index < block.trial_type.length - 1 &&
                                          ', '}
                                      </span>
                                    );
                                  }
                                  return null;
                                })}
                                {block.trial_type.length > 2 &&
                                  !expandedTrialTypes[block.name] && (
                                    <span
                                      onClick={() =>
                                        handleTrialTypeDisplay(block.name)
                                      }
                                      style={{
                                        cursor: 'pointer',
                                        color: 'blue',
                                      }}
                                    >
                                      <MoreHorizIcon />
                                    </span>
                                  )}
                                ]
                              </div>

                              <br />
                              <div style={{ 'text-align': 'right' }}>
                                <div
                                  style={{
                                    'font-size': 'small',
                                    display: 'inline',
                                    'text-align': 'right',
                                  }}
                                >
                                  [
                                  {block.roles &&
                                    block.roles?.map((rolesObject, index) => {
                                      let name;
                                      if (index === block.roles.length - 1)
                                        name = rolesObject.name;
                                      else name = `${rolesObject.name}, `;
                                      return (
                                        <div
                                          style={{
                                            'font-size': 'small',
                                            display: 'inline',
                                            color:
                                              rolesObject.name === 'CRC'
                                                ? 'red'
                                                : rolesObject.name === 'INV'
                                                ? 'orange'
                                                : 'black',
                                          }}
                                        >
                                          {name}
                                        </div>
                                      );
                                    })}
                                  ]
                                </div>
                              </div>
                            </a>
                          </div>
                          {patientId === undefined &&
                            role !== 'Investigator' &&
                            role !== 'Inv_coordinator' &&
                            role !== 'Reviewer' && (
                              <div className="col-auto">
                                <input
                                  type="checkbox"
                                  checked={selectedVisit.trial_block?.some(
                                    (trial) => trial.name === block.name
                                  )}
                                  name={block.name}
                                  onChange={(e) => handleBlockChange(block)}
                                  className="form-check-input"
                                />
                              </div>
                            )}
                        </div>
                      )
                    );
                  })}
                  <AddTrialTypePopUp
                    showTrialTypeModal={showTrialTypeModal}
                    setShowTrialTypeModal={setShowTrialTypeModal}
                    blockId={blockId}
                    trialTypeIds={trialTypeIds}
                    setTrialTypeIds={setTrialTypeIds}
                    AllVisitsData={AllVisitsData}
                  />
                  {(role === 'CRO Admin' ||
                    role === 'Cro_coordinator' ||
                    role === 'Data Manager') &&
                    !patientId &&
                    localBlocks?.map((block) => {
                      if (
                        !selectedVisit.trial_block?.some(
                          (trial) => trial.name === block.name
                        )
                      ) {
                        return (
                          <div className="row align-items-center">
                            <div className="col ml--2">
                              <a
                                style={{
                                  cursor: 'pointer',
                                  color:
                                    selectedBlock === block.name
                                      ? 'black'
                                      : 'var(--bs-link-color)',
                                }}
                                onClick={() =>
                                  handleSelectBlock(block.name, block.new_block)
                                }
                              >
                                {block.name}
                                <br />
                                <div
                                  style={{
                                    'font-size': 'small',
                                    color: 'black',
                                  }}
                                >
                                  [
                                  {block.trial_type.map(
                                    (blockObject, index) => {
                                      let name = blockObject.title
                                        ? blockObject.title
                                        : blockObject.label;
                                      if (index === block.trial_type.length - 1)
                                        return name;
                                      else return `${name}, `;
                                    }
                                  )}
                                  ]
                                </div>

                                <div style={{ 'text-align': 'right' }}>
                                  <div
                                    style={{
                                      'font-size': 'small',
                                      display: 'inline',
                                      'text-align': 'right',
                                    }}
                                  >
                                    [
                                    {block.roles &&
                                      block.roles.map((rolesObject, index) => {
                                        let name;
                                        if (index === block.roles.length - 1)
                                          name = rolesObject.name;
                                        else name = `${rolesObject.name}, `;
                                        return (
                                          <div
                                            style={{
                                              'font-size': 'small',
                                              display: 'inline',
                                              color:
                                                rolesObject.name === 'CRC'
                                                  ? 'red'
                                                  : rolesObject.name === 'INV'
                                                  ? 'orange'
                                                  : 'black',
                                            }}
                                          >
                                            {name}
                                          </div>
                                        );
                                      })}
                                    ]
                                  </div>
                                </div>
                              </a>
                            </div>
                            {patientId === undefined &&
                              role !== 'Investigator' &&
                              role !== 'Inv_coordinator' &&
                              role !== 'Reviewer' && (
                                <div className="col-auto">
                                  <input
                                    type="checkbox"
                                    checked={selectedVisit.trial_block?.some(
                                      (trial) => trial.name === block.name
                                    )}
                                    name={block.name}
                                    onChange={(e) => handleBlockChange(block)}
                                    className="form-check-input"
                                  />
                                </div>
                              )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
              <div className="col-md-9">
                <div className="clinicCard borderless table-wrapper-scroll-y my-custom-scrollbar2">
                  <div className="col-md-12 text-end">
                    {selectedBlock === 'Patient Activity in this Visit' &&
                      ![-4, -2, -1].includes(selectedVisit?.visit_number) && (
                        <button
                          onClick={() => handleVisitUpdate()}
                          className="btn btn-primary btn-sm mx-3"
                          disabled={!hasChanged}
                          style={{ marginLeft: '85%' }}
                        >
                          Save Data
                        </button>
                      )}
                  </div>
                  {selectedBlock === 'Patient Activity in this Visit' ? (
                    selectedVisit?.visit_number !== -4 ? (
                      <>
                        <div className="row" style={{ borderBottom: '0' }}>
                          <div className="col-md-6">
                            <label>POEM</label>
                            {role === 'Investigator' ||
                            role === 'Sub_investigator' ||
                            role === 'Inv_coordinator' ||
                            role === 'Super Admin' ||
                            role === 'Reviewer' ? (
                              <>
                                <input
                                  className="form-control"
                                  value={selectedVisit.survey_form?.name}
                                  disabled={true}
                                ></input>
                              </>
                            ) : (
                              <>
                                <input
                                  style={{ marginLeft: '1em' }}
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={surveyFormBool}
                                  name="surveyBool"
                                  id="flexSwitchCheckDefault"
                                  onChange={(e) => handleBoolOperation(e)}
                                ></input>
                                {surveyFormBool && (
                                  <>
                                    <div className="row justify-content-between w-100 m-0">
                                      <div className="col-md-7">
                                        <select
                                          className="form-control"
                                          onChange={(e) =>
                                            handleVisitFieldChange(e)
                                          }
                                          name="survey_form"
                                          value={selectedVisit.survey_form?.id}
                                          disabled={
                                            surveyFormOption !== 'list' &&
                                            'disabled'
                                          }
                                        >
                                          <option value="">Select</option>
                                          {allSurveys.map((survey) => {
                                            return (
                                              <>
                                                <option value={survey.id}>
                                                  {survey.name}
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                      </div>

                                      <div className="col-md-5">
                                        <button
                                          class="btn btn-primary"
                                          onClick={() => {
                                            setAddNewSurvey(true);
                                          }}
                                        >
                                          Add New
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label>Consent Form</label>
                            {role === 'Investigator' ||
                            role === 'Sub_investigator' ||
                            role === 'Inv_coordinator' ||
                            role === 'Super Admin' ||
                            role === 'Reviewer' ? (
                              <>
                                <input
                                  className="form-control"
                                  value={selectedVisit.consent_form?.title}
                                  disabled={true}
                                ></input>
                              </>
                            ) : (
                              <>
                                <input
                                  style={{ marginLeft: '1em' }}
                                  class="form-check-input"
                                  type="checkbox"
                                  name="consentBool"
                                  checked={consentFormBool}
                                  id="flexSwitchCheckDefault"
                                  onChange={(e) => handleBoolOperation(e)}
                                ></input>
                                {consentFormBool && (
                                  <>
                                    <div className="row justify-content-between w-100 m-0">
                                      <div className="col-md-7">
                                        <select
                                          className="form-control"
                                          onChange={(e) =>
                                            handleVisitFieldChange(e)
                                          }
                                          name="consent_list_id"
                                          value={selectedVisit.consent_form?.id}
                                          disabled={
                                            consentFormOption !== 'list' &&
                                            'disabled'
                                          }
                                        >
                                          <option value="">Select</option>
                                          {allConsents.map((consent) => {
                                            return (
                                              <>
                                                <option value={consent.id}>
                                                  {consent.title}
                                                </option>
                                              </>
                                            );
                                          })}
                                        </select>
                                      </div>

                                      <div className="col-md-5">
                                        <button
                                          disabled={
                                            consentFormOption !== 'new' &&
                                            'disabled'
                                          }
                                          class="btn btn-primary"
                                          onClick={() => setModalShow(true)}
                                        >
                                          Add New
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-md-6">
                            <label class="form-check-label">Video Proof?</label>
                            <input
                              style={{ marginLeft: '1em' }}
                              type="checkbox"
                              disabled={
                                role === 'Investigator' ||
                                role === 'Sub_investigator' ||
                                role === 'Inv_coordinator' ||
                                role === 'Reviewer'
                                  ? true
                                  : false
                              }
                              class="form-check-input"
                              name="drug_video"
                              id="drug_video"
                              checked={selectedVisit.drug_video}
                              onChange={(e) => {
                                handleVisitFieldChange(e);
                              }}
                            ></input>
                          </div>

                          <div className="col-md-4">
                            <div
                              className={`d-flex justify-content-between align-items-center ${
                                selectedVisit?.visit_number === 0 ||
                                selectedVisit?.visit_number === -3
                                  ? 'flex-row-reverse'
                                  : ''
                              }`}
                            >
                              {(role === 'CRO Admin' ||
                                role === 'Cro_coordinator' ||
                                role === 'Data Manager') &&
                                (selectedVisit?.visit_number === 0 ||
                                  selectedVisit?.visit_number === -3) && (
                                  <a
                                    onClick={handleSelectAll}
                                    style={{
                                      color: 'blue',
                                      cursor: 'pointer',
                                    }}
                                    className="text-end small ms-2"
                                  >
                                    {selectAll
                                      ? 'Deselect All Extra Photos'
                                      : 'Select All Extra Photos'}
                                    <Badge
                                      badgeContent={
                                        selectedVisit?.extra_photos?.length || 0
                                      }
                                      color="secondary"
                                      showZero
                                      overlap="circular"
                                      className="mb-4 ms-2"
                                    ></Badge>
                                  </a>
                                )}
                              <div
                                className={`d-flex ${
                                  role === 'Investigator' ||
                                  role === 'Inv_coordinator' ||
                                  role === 'Sub_investigator'
                                    ? 'flex-column align-items-start w-100'
                                    : 'align-items-center'
                                }`}
                              >
                                <div className="d-flex w-100 justify-content-between align-items-center">
                                  <label
                                    className={`small ${
                                      selectedVisit?.visit_number === 0
                                        ? 'text-end'
                                        : 'text-start'
                                    }`}
                                  >
                                    Photos
                                    {(role === 'Investigator' ||
                                      role === 'Sub_investigator' ||
                                      role === 'Inv_coordinator') && (
                                      <Badge
                                        badgeContent={
                                          selectedVisit?.extra_photos?.length ||
                                          0
                                        }
                                        showZero
                                        sx={{
                                          '& .MuiBadge-badge': {
                                            backgroundColor: '#d3d3d3',
                                            color: 'red',
                                            position: 'absolute',
                                            top: '-20px',
                                            right: '-25px',
                                            transform: 'none',
                                            zIndex: 1,
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50%',
                                            fontSize: '12px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                          },
                                        }}
                                      ></Badge>
                                    )}
                                  </label>

                                  {selectedVisit?.is_maximum_photo_types &&
                                    (role === 'Investigator' ||
                                      role === 'Sub_investigator' ||
                                      role === 'Inv_coordinator') && (
                                      <>
                                        <label className="text-end small ms-2">
                                          Maximum Photos:{' '}
                                          {selectedVisit?.maximum_photo_types}
                                        </label>
                                      </>
                                    )}
                                </div>
                              </div>
                            </div>

                            <Select
                              isDisabled={disable}
                              value={selectedVisit?.extra_photos}
                              isMulti
                              className="basic-multi-select"
                              options={photoTypes}
                              onChange={handlePhotoTypeChange}
                            />
                          </div>

                          {patientId !== undefined && (
                            <>
                              <div className="col-md-6">
                                <label>Visit Start Date</label>
                                <input
                                  onChange={(e) => {
                                    setSelectedVisit({
                                      ...selectedVisit,
                                      start_date: moment(e.target.value)
                                        .utc()
                                        .format(),
                                    });
                                    setHasChanged(true);
                                  }}
                                  class="form-control"
                                  disabled={
                                    selectedVisit.visit_number === -2 ||
                                    selectedVisit.visit_number === -1 ||
                                    selectedVisit.visit_number === -3 ||
                                    patientId !== undefined
                                  }
                                  type="datetime-local"
                                  value={moment(
                                    selectedVisit.visit_number === -2 ||
                                      selectedVisit.visit_number === -1
                                      ? selectedVisit.created_at
                                      : selectedVisit?.start_date
                                  ).format('YYYY-MM-DDTHH:mm')}
                                  inputProps={{
                                    placeholder: 'mm/dd/yy 00:00 AM',
                                    disabled:
                                      role === 'CRO Admin' ||
                                      role === 'Cro_coordinator' ||
                                      role === 'Data Manager'
                                        ? true
                                        : false || patientId === undefined,
                                  }}
                                />
                              </div>
                            </>
                          )}

                          <fieldset disabled={instructionDisable}>
                            {role === 'CRO Admin' ||
                            role === 'Investigator' ||
                            role === 'Sub_investigator' ||
                            role === 'Cro_coordinator' ||
                            role === 'Data Manager' ||
                            role === 'Inv_coordinator' ||
                            role === 'Super Admin' ? (
                              <div className="row">
                                <div className="col-md-6">
                                  <label> Instructions:</label>
                                  <textarea
                                    class="form-control"
                                    name="contnt"
                                    rows={2}
                                    type="text"
                                    disabled={
                                      trial && !trial.can_edit_instructions
                                    }
                                    value={
                                      selectedVisit.visit_instructions?.contnt
                                    }
                                    onChange={handleVisitFieldChange}
                                  ></textarea>
                                </div>

                                {selectedVisit?.visit_number !== -1 &&
                                  selectedVisit?.visit_number !== -2 &&
                                  selectedVisit?.visit_number !== -3 && (
                                    <div className="col-md-6 mt-4">
                                      <label class="form-check-label">
                                        Save instructions for all coming visits
                                      </label>
                                      <input
                                        style={{ marginLeft: '1em' }}
                                        type="checkbox"
                                        class="form-check-input"
                                        name="is_all_instruction"
                                        id="drug_video"
                                        disabled={
                                          role !== 'CRO Admin' &&
                                          role !== 'Cro_coordinator' &&
                                          role !== 'Data Manager' &&
                                          (trial?.can_edit_instructions
                                            ? false
                                            : true)
                                        }
                                        checked={
                                          selectedVisit.is_all_instruction
                                        }
                                        onChange={() => {
                                          handleInstruction();
                                        }}
                                      ></input>
                                    </div>
                                  )}
                              </div>
                            ) : (
                              ''
                            )}
                          </fieldset>
                        </div>
                      </>
                    ) : (
                      <> No Activity for this Visit </>
                    )
                  ) : (
                    <>
                      {newForm && (
                        <EcrfBlocks
                          visit_number={selectedVisit?.visit_number}
                          trialId={trialId}
                          photo_session={selectedVisit.photo_session}
                          selectedSessionId={selectedSessionId}
                          selectedBlock={selectedBlock}
                          patient_id={patientId}
                          setMessage={setMessage}
                          newForm={newForm}
                          setTypeOfMessage={setTypeOfMessage}
                          AllVisitsData={AllVisitsData}
                          trialDetailApi={trialDetailApi}
                          setBlockId={setBlockId}
                        />
                      )}

                      <>
                        {selectedBlock ===
                          'Participant Contact Information' && (
                          <Participant_Contact_Info
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                          />
                        )}
                        {selectedBlock === 'Site & Patient Identification' && (
                          <Site_Patient_Identification
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Demographics' && (
                          <Demographics
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Informed Consent' && (
                          <Informed_Consent
                            patient_id={patientId}
                            trialId={trialId}
                            visit_number={selectedVisit?.visit_number}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Birth Control UPT' && (
                          <Birth_Control
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            setSelectedVisit={setSelectedVisit}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Inclusion Criteria' && (
                          <Inclusion_Criteria
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            selectedSessionId={selectedSessionId}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Exclusion Criteria' && (
                          <Exclusion_Criteria
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Target Lesion Erythema Assessment' && (
                          <Target_Lesion_Erythema_Assessment
                            selectedVisit={selectedVisit}
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                          />
                        )}
                        {selectedBlock === 'Global Severity Score' && (
                          <Global_Severity_Score
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Target Lesion Severity Assessment' && (
                          <Target_Lesion_Severity_Assessment
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                          />
                        )}
                        {selectedBlock === 'Intralesional Injections' && (
                          <Intralesional_Injection
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Participant Assessments' && (
                          <PARTICIPANT_ASSESSMENTS
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Target Lesion Identification' && (
                          <Target_Lesion_Identification
                            patient_id={patientId}
                            trialId={trialId}
                            visit_number={selectedVisit?.visit_number}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Reminders' && (
                          <Reminders
                            setSelectedVisit={setSelectedVisit}
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Target Lesion Improvement Assessment' && (
                          <TargetLesionImprovementAssessment
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                          />
                        )}
                        {selectedBlock === 'Target Lesion Videography' && (
                          <Target_Lesion_Videography
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Target Lesion Photography - PRE-Injection' && (
                          <Target_Lesion_Photography_Pre_Injection
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Target Lesion Photography - POST-Injection' && (
                          <Target_Lesion_Photography_Post_Injection
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            selectedSessionId={selectedSessionId}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Medical History Concomitant Medication Treatment' && (
                          <Medical_History_Concomitant_Medication_Treatment
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            selectedSessionId={selectedSessionId}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Photographic App (Before) Injection' && (
                          <Photographic_App_Before_Injection
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setSelectedVisit={setSelectedVisit}
                            selectedSessionId={selectedSessionId}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Photographic App (After) Injection' && (
                          <Photographic_App_After_Injection
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            selectedSessionId={selectedSessionId}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Participant Assessments - Pre-Injection' && (
                          <Participant_Assessments_Pre_Injection
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock === 'Target Lesion Injection Pain' && (
                          <Target_Lesion_Injection_Pain
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            extra_photo={selectedVisit.extra_photos}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            selectedSessionId={selectedSessionId}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                          />
                        )}
                        {selectedBlock ===
                          'Concomitant Medication/Treatment Work Sheet' && (
                          <Concomitant_Medication
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setSelectedVisit={setSelectedVisit}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Adverse Event Log' && (
                          <Adverse_Event
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            setSelectedVisit={setSelectedVisit}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Medical History Work Sheet' && (
                          <Medical_surgical
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            setSelectedVisit={setSelectedVisit}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}

                        {selectedBlock === 'Informed Consent (Stem Cell)' && (
                          <Informed_Consent_Stem_Cell
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            selectedSessionId={selectedSessionId}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock ===
                          'Demographic Information (Stem Cell)' && (
                          <Demographics_Information_Stem_Cell
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Inclusion Criteria (Stem Cell)' && (
                          <Inclusion_Criteria_Stem_Cell
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Exclusion Criteria (Stem Cell)' && (
                          <Exclusion_Criteria_Stem_Cell
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Skin Biopsy (Stem Cell)' && (
                          <Skin_Biopsy_Stem_Cell
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Visit Comments (Stem Cell)' && (
                          <Visit_Comments_Stem_Cell
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Suture_removal' && (
                          <Sutures_Removed_Stem_Cell
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Fitzpatrick Skin Type' && (
                          <Fitzpatrick_Skin_Type
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Global Severity Score General' && (
                          <Global_Severity_Score_General
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Child Bearing Potential' && (
                          <Child_Bearing_Potential
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'PhotoGraphic App' && (
                          <PhotoGraphic_App
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'Dermatologic Assessment' && (
                          <Dermatologic_Assessment
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                        {selectedBlock === 'EXIT FORM' && (
                          <Exit_Form
                            patient_id={patientId}
                            visit_number={selectedVisit?.visit_number}
                            trialId={trialId}
                            photo_session={selectedVisit.photo_session}
                            setMessage={setMessage}
                            setTypeOfMessage={setTypeOfMessage}
                            setSelectedVisit={setSelectedVisit}
                            globalVisits={AllVisitsData}
                            patient={selectedVisit.patient}
                            patientDetailApi={patientDetailApi}
                            setHasChanged={setHasChanged}
                            hasChanged={hasChanged}
                            modalVisitChangeShow={modalVisitChangeShow}
                            setModalVisitChangeShow={setModalVisitChangeShow}
                            infoData={infoData}
                            selectedSessionId={selectedSessionId}
                            intro_date={selectedVisit.intro_date}
                            intro_time={selectedVisit.intro_time}
                          />
                        )}
                      </>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};
export default AllBlocksAccordion;
