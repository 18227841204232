import React from 'react';

import NavBar from '../../components/navBar/navBar';
import useFetch from '../../hooks/useFetch';

const PhotoReport = () => {
  const { handleRefresh } = useFetch();

  return (
    <>
      <NavBar active="photo-report" />
      <div class="container-fluid">
        <div className="row patient_table">
          <div className="col-md-12">
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-6">
                  <h5>Patients Photo Evaluation Report</h5>
                </div>
                <div className="container col-md-6 d-flex justify-content-end">
                  <div className="row">
                    <div className="col-sm">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={handleRefresh}
                      >
                        Refresh
                      </button>
                    </div>
                    <div className="col-sm">
                      <button type="button" className="btn btn-primary btn-sm">
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>PRACTITIONER</th>
                  <th>CLINIC</th>
                  <th>EMAIL</th>
                  <th>LAST NAME</th>
                  <th>FIRST NAME</th>
                  <th>SESSION START</th>
                  <th>SESSION CLOSE</th>
                  <th>REPOSE TIMESTAMP</th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 6 }).map((item, index) => (
                  <tr key={index}>
                    <td>Sunil Dhawan MD </td>
                    <td>Center For Dermatology Cosmetic & Laser Surgery</td>
                    <td>bmahbod+apr12@gmail.com</td>
                    <td>Mahbod</td>
                    <td>Apr12</td>
                    <td>2022-05-02-12-39</td>
                    <td>2022-05-02-12-40</td>
                    <td>2022-05-02-12-39</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoReport;
