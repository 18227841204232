import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Card } from '@mui/material';
import axios from 'axios';

import NavBar from '../../components/navBar/navBar';
import PatientMenu from '../Patient/PatientMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { __API_URL__ } from '../../SERVER_URL';

const PoemList = () => {
  const { _id_ } = useParams();
  const token = localStorage.getItem('token');
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const role = user_data.role;
  const navigate = useNavigate();
  const loaderContext = useContext(LoaderContext);

  const [visitData, setVisitData] = useState([]);
  const [infoData, setInfoData] = useState({});

  const getPhotoSession = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    axios
      .get(
        `${__API_URL__}/api/patient/patient/all/get_visits_data/?patient_id=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setVisitData(res.data);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
        }
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const SinglePatient = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const routeTo = (id) => {
    navigate('/patient/' + _id_ + '/poem/' + id);
  };

  useEffect(() => {
    getPhotoSession();
    SinglePatient();
  }, []);

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs
            B="POEM/ePRO/eDiary"
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
          ></Breadcrumbs>
          <PatientMenu active="poem" data={infoData} />
          <div className="tableHeader">
            <div className="row"></div>
            {loaderContext.loaderCount > 0 ? (
              <Loader />
            ) : (
              <>
                <Card className="col-md-12 my-3">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {role === 'Super Admin' ||
                          role === 'Investigator' ||
                          user_data.role === 'Sub_investigator' ||
                          role === 'CRO Admin' ||
                          role === 'Cro_coordinator' ||
                          role === 'Data Manager' ||
                          role === 'Inv_coordinator'
                            ? 'Trial Name'
                            : 'Session Area'}
                        </th>
                        <th>Visit Type</th>

                        {(role === 'Super Admin' ||
                          role === 'Investigator' ||
                          user_data.role === 'Sub_investigator' ||
                          role === 'CRO Admin' ||
                          role === 'Cro_coordinator' ||
                          role === 'Data Manager' ||
                          role === 'Inv_coordinator') && (
                          <>
                            <th>Visit Name</th>
                          </>
                        )}
                        {(role === 'Super Admin' ||
                          role === 'Investigator' ||
                          user_data.role === 'Sub_investigator' ||
                          role === 'CRO Admin' ||
                          role === 'Cro_coordinator' ||
                          role === 'Data Manager' ||
                          role === 'Inv_coordinator') && (
                          <>
                            <th>Visit Open Date</th>
                            <th>Visit Close Date</th>
                            <th>
                              {user_data.role === 'CRO Admin' ||
                              user_data.role === 'Investigator' ||
                              user_data.role === 'Sub_investigator' ||
                              user_data.role === 'Inv_coordinator' ||
                              role === 'Cro_coordinator' ||
                              role === 'Data Manager'
                                ? 'Subject Start Date'
                                : 'Patient Start Date'}
                            </th>
                            <th>
                              {user_data.role === 'CRO Admin' ||
                              user_data.role === 'Investigator' ||
                              user_data.role === 'Sub_investigator' ||
                              user_data.role === 'Inv_coordinator' ||
                              role === 'Cro_coordinator' ||
                              role === 'Data Manager'
                                ? 'Subject Completed Date'
                                : 'Patient Completed Date'}
                            </th>
                            <th>Is Skipped</th>
                            <th>Has Activity?</th>
                          </>
                        )}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {visitData?.map((data) => (
                        <>
                          {data?.visit_session?.map((visit) => {
                            return (
                              visit?.visit_type !== 'Unscheduled Event' && (
                                <tr
                                  key={visit?.id}
                                  onClick={
                                    visit?.id ? (e) => routeTo(visit?.id) : null
                                  }
                                >
                                  <td>{visit?.trial_area}</td>
                                  <td>
                                    {visit?.visit_type
                                      ? visit?.visit_type
                                      : 'Regular'}
                                  </td>
                                  {(role === 'Investigator' ||
                                    user_data.role === 'Sub_investigator' ||
                                    role === 'CRO Admin' ||
                                    role === 'Cro_coordinator' ||
                                    role === 'Data Manager' ||
                                    role === 'Inv_coordinator' ||
                                    role === 'Super Admin') && (
                                    <>
                                      <td>
                                        {visit.visit_name
                                          ? visit.visit_name
                                          : visit.visit_type
                                          ? visit.visit_type
                                          : `Visit ${visit.visit_number}`}
                                      </td>
                                    </>
                                  )}
                                  <td>
                                    {visit.actual_date ? (
                                      <>
                                        {new Date(
                                          visit.actual_date + 'Z'
                                        ).toDateString()}
                                        ,&nbsp;
                                        {new Date(
                                          visit.actual_date + 'Z'
                                        ).toLocaleTimeString()}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    {visit.visit_close_date ? (
                                      <>
                                        {new Date(
                                          visit.visit_close_date + 'Z'
                                        ).toDateString()}
                                        ,&nbsp;
                                        {new Date(
                                          visit.visit_close_date + 'Z'
                                        ).toLocaleTimeString()}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  {visit?.start_date && !visit?.is_skipped ? (
                                    <td
                                      style={{
                                        textAlignLast: 'left',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {new Date(
                                        visit?.start_date + 'Z'
                                      ).toDateString()}
                                      ,&nbsp;
                                      {new Date(
                                        visit?.start_date + 'Z'
                                      ).toLocaleTimeString()}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        textAlignLast: 'center',
                                        textAlign: 'center',
                                      }}
                                    >
                                      -
                                    </td>
                                  )}
                                  {visit?.is_skipped ? (
                                    <td
                                      style={{
                                        textAlignLast: 'center',
                                        textAlign: 'center',
                                      }}
                                    >
                                      -
                                    </td>
                                  ) : visit?.completed_date ? (
                                    <td
                                      style={{
                                        textAlignLast: 'left',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {new Date(
                                        visit?.completed_date + 'Z'
                                      ).toDateString()}
                                      ,&nbsp;
                                      {new Date(
                                        visit?.completed_date + 'Z'
                                      ).toLocaleTimeString()}
                                    </td>
                                  ) : (
                                    <td
                                      style={{
                                        textAlignLast: 'center',
                                        textAlign: 'center',
                                      }}
                                    >
                                      -
                                    </td>
                                  )}
                                  <td>
                                    {visit?.is_skipped
                                      ? 'True'
                                      : visit?.is_skipped === false
                                      ? 'False'
                                      : '-'}
                                  </td>
                                  <td>
                                    {visit?.has_activity ? 'True' : 'False'}
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-primary btn-sm m-2"
                                    >
                                      View Case
                                    </button>
                                  </td>
                                </tr>
                              )
                            );
                          })}
                        </>
                      ))}
                    </tbody>
                  </table>
                </Card>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PoemList;
