import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

import PatientTabel from '../components/Patient/PatientDetail/PatientTabel';
import AddNewPatientModal from '../components/Modals/AddNewPatient';
import ClinicAdmin from '../components/ClinicAdmin/ClinicAdmin';
import SurveyPoem from '../components/Survey/SurveyPoem';
import MessageBox from '../components/Alert/MessageBox';
import PopMessage from '../components/PopUp/PopMessage';
import Breadcrumbs from '../components/breadcrumbs';
import NavBar from '../components/navBar/navBar';
import { __API_URL__ } from '../SERVER_URL';
import useUser from '../hooks/user';

const NewDashboard = (props) => {
  const user = useUser();
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const role = user_data.role;
  const [checkPatient, setCheckPatient] = useState(false);
  const now = moment();
  const today = now;
  const [dateTime, changeDateTime] = useState(today);
  const [changedPatientTable, setChangedPatientTable] = useState(false);
  const token = localStorage.getItem('token');
  const [patientData, setPatientData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    birth_date: '',
    drug: '',
  });
  const [clinicData, setClinicData] = useState({
    email: '',
    first_name: '',
    last_name: '',
  });
  const [clinicPatient, setClinicPatient] = useState(false);
  const [searchDrug, setSearchDrug] = useState('');
  const [clinics, setClinics] = useState([]);
  const [drugKits, setDrugKits] = useState([]);
  let navigate = useNavigate();
  const [pageItemCount, setPageItemCount] = useState(10);
  const [Search, setSearch] = useState({
    email: '',
    fName: '',
    lName: '',
    completed_photo_session_count: '',
    clinic: '',
    completed_photo_session_count: '',
    fired: 'FIRED',
    vip: 'VIP',
    do_not_call: '',
    deactive: 'True',
    archived: 'False',
    trial_name: '',
    trial_status: '',
    pass: '',
    page: 1,
    itemCount: pageItemCount,
    ordering: '-log_created_at',
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [cusMessageType, setCusMessageType] = useState('cusMessages');
  const [cusMessage, setCusMessage] = useState('');
  const [typeOfPatient, setTypeOfPatient] = useState('active');
  const [Loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [clinicModalShow, setClinicModalShow] = useState(false);
  const [surveyModalShow, setSurveyModalShow] = useState(false);
  const [page, setPage] = useState(1);
  const [manualDrug, setManualDrug] = useState(false);

  useEffect(() => {
    setSearch({ ...Search, itemCount: pageItemCount });
  }, [pageItemCount]);

  const handleDeactivateActivate = (type) => {
    if (rowSelectionModel.length == 0) {
      switch (type) {
        case 'active':
          setCusMessage('Select Patient To Activate!');
          break;
        case 'deactive':
          setCusMessage('Select Patient To Deactivate!');
          break;
        case 'archive':
          setCusMessage('Select Patient To Archive!');
        case 'unarchive':
          setCusMessage('Select Patient To Unarchive!');
      }
      setCusMessageType('warning');

      setTimeout(() => {
        document.getElementById('cusMessages').style.right = '2rem';
      }, 100);
      setTimeout(() => {
        document.getElementById('cusMessages').style.right = '-99rem';
      }, 3000);
    } else {
      const data = {
        patient_ids: rowSelectionModel,
      };
      let endpoint = '';
      if (type === 'active' || type === 'deactive')
        endpoint = 'deactivate_activate_patient';
      if (type === 'archive' || type === 'unarchive')
        endpoint = 'archive_patient';

      axios
        .post(`${__API_URL__}/api/auth/staff/${endpoint}/${type}/`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          setChangedPatientTable(true);

          switch (type) {
            case 'active':
              setCusMessage('Activation was successful!');
              break;
            case 'deactive':
              setCusMessage('Deactivation was successful!');
              break;
            case 'archive':
              setCusMessage('Selected patients archived successfully!');
              break;
            case 'unarchive':
              setCusMessage('Selected patients unarchived successfully!');
              break;
          }

          setCusMessageType('success');
          setRowSelectionModel([]);

          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '2rem';
          }, 100);
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '-99rem';
          }, 3000);
        })
        .catch((err) => {
          if (err == 'Error: Request failed with status code 401') {
            user.actions.logout();
          }
        })
        .finally(() => {
          setChangedPatientTable(false);
        });
    }
  };

  const handlePatientList = (type) => {
    setChangedPatientTable(false);

    if (type == 'active') {
      setRowSelectionModel([]);
      setSearch((Search) => ({
        ...Search,
        deactive: 'True',
        archived: 'False',
      }));
      setTypeOfPatient('active');
    } else if (type == 'deactive') {
      setRowSelectionModel([]);
      setSearch((Search) => ({
        ...Search,
        deactive: 'False',
        archived: 'False',
      }));
      setTypeOfPatient('deactive');
    } else if (type == 'archived') {
      setRowSelectionModel([]);
      setSearch((Search) => ({
        ...Search,
        archived: 'True',
        deactive: 'True',
      }));
      setTypeOfPatient('archived');
    }
  };

  const handleRefresh = () => {
    SearchData(Search);
  };

  const SearchData = () => {
    axios
      .get(`${__API_URL__}/api/patient/patient/allpatient/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPatientData(res.data);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      });
  };

  const GetClinics = () => {
    axios
      .get(`${__API_URL__}/api/patient/patient/get_clinic_admin/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setClinics(res.data);
      })
      .catch((err) => {
        if (err == 'Error: Request failed with status code 401') {
          user.actions.logout();
        }
      });
  };

  const addPatientApi = () => {
    if (!patientData?.email.includes('@')) {
      setCusMessage('Invalid Email!');
      setCusMessageType('error');
      setTimeout(() => {
        document.getElementById('cusMessages').style.right = '2rem';
      }, 100);
      setTimeout(() => {
        document.getElementById('cusMessages').style.right = '-99rem';
      }, 5000);
    } else {
      const Createdata = {
        email: patientData.email,
        confirm_email: patientData.confirm_email,
        first_name: patientData.first_name,
        middle_name_initial: patientData.middle_name_initial,
        last_name: patientData.last_name,
        birth_date: patientData.birth_date,
        phone: patientData.phone,
        participant_number: patientData.participant_number,
        drug: patientData.drug,
        trial: patientData.trial_id,
        start_date: dateTime,
        checkPatient: checkPatient,
      };
      axios
        .post(`${__API_URL__}/api/patient/patient/add_patient/`, Createdata, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((res) => {
          res = res.data;
          setCheckPatient(false);
          setModalShow(false);
          setPatientData(res);
          setCusMessage('Patient successfully added');
          setCusMessageType('success');
          SearchData(Search);

          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '2rem';
          }, 100);
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '-99rem';
          }, 3000);
          setTimeout(() => {
            navigate(`/patient/${res.id}/ecrf/`);
          }, 2000);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            setCheckPatient(true);
          } else {
            setCusMessage(err.response.data.message);
            setCusMessageType('error');
            setTimeout(() => {
              document.getElementById('cusMessages').style.right = '2rem';
            }, 100);
            setTimeout(() => {
              document.getElementById('cusMessages').style.right = '-99rem';
            }, 5000);
          }
        });
    }
  };

  const GetDrugKitDropDown = () => {
    axios
      .get(
        `${__API_URL__}/api/dropdown_drugs/?page=${page}&trial=${patientData?.trial_id}&name=${searchDrug}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setDrugKits((prevDrugKits) => res.data?.results.slice(0, 5));
      });
  };

  const AddClinicPatient = () => {
    if (!clinicData?.email.includes('@')) {
      setCusMessage('Invalid Email!');
      setCusMessageType('error');
      setTimeout(() => {
        document.getElementById('cusMessages').style.right = '2rem';
      }, 100);
      setTimeout(() => {
        document.getElementById('cusMessages').style.right = '-99rem';
      }, 5000);
    } else {
      const ClinicData = {
        email: clinicData.email,
        confirm_email: clinicData.confirm_email,
        first_name: clinicData.first_name,
        last_name: clinicData.last_name,
        phone: clinicData.phone,
        clinics: clinicData.clinics,
      };
      axios
        .post(
          `${__API_URL__}/api/patient/patient/add_clinic_patient/`,
          ClinicData,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((res) => {
          setClinicData(res.data);
          setClinicModalShow(false);
          setClinicPatient(true);
          setCusMessage('Patient Successfully Added');
          setCusMessageType('success');
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '2rem';
          }, 100);
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '-99rem';
          }, 3000);
        })
        .catch((err) => {
          setCusMessage(err.response.data.message);
          setCusMessageType('error');
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '2rem';
          }, 100);
          setTimeout(() => {
            document.getElementById('cusMessages').style.right = '-99rem';
          }, 5000);
        });
    }
  };

  useEffect(() => {
    if (searchDrug && manualDrug) {
      const delay = 3000;
      const timerId = setTimeout(() => {
        GetDrugKitDropDown();
      }, delay);

      return () => clearTimeout(timerId);
    }
  }, [searchDrug]);

  useEffect(() => {
    if (patientData?.trial_id && manualDrug) {
      GetDrugKitDropDown();
    }
  }, [patientData?.trial_id]);

  useEffect(() => {
    localStorage.setItem('trial_name_filter', '');
    localStorage.setItem('trial_status_filter', '');
    localStorage.setItem('pass_number', 1);
  }, []);
  // Modal variables
  return (
    <>
      <MessageBox msg={cusMessage} type={cusMessageType} />
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs />
        <div className="row patient_table patientList">
          <div className="col-md-2">
            <div className="sideBar">
              <h5>
                {user_data.role === 'CRO Admin' ||
                user_data.role === 'Investigator' ||
                user_data.role === 'Sub_investigator' ||
                user_data.role === 'Inv_coordinator' ||
                role === 'Cro_coordinator' ||
                role === 'Data Manager'
                  ? 'Subjects Menu'
                  : 'Patient Menu'}
              </h5>
              {role !== 'Reviewer' &&
                role !== 'CRO Admin' &&
                role !== 'Cro_coordinator' &&
                role !== 'Data Manager' && (
                  <>
                    <ul>
                      <li>
                        <Link
                          to=""
                          onClick={(e) => {
                            handlePatientList('active');
                          }}
                          className={typeOfPatient == 'active' ? 'active' : ''}
                        >
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          {user_data.role === 'CRO Admin' ||
                          user_data.role === 'Investigator' ||
                          user_data.role === 'Sub_investigator' ||
                          user_data.role === 'Inv_coordinator' ||
                          (role === 'Cro_coordinator' &&
                            role === 'Data Manager')
                            ? 'Active Subjects'
                            : 'Active Patients'}
                        </Link>
                      </li>
                      <li>
                        <Link
                          to=""
                          onClick={(e) => {
                            handlePatientList('archived');
                          }}
                          className={
                            typeOfPatient == 'archived' ? 'active' : ''
                          }
                        >
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                            alt=""
                          />
                          {user_data.role === 'CRO Admin' ||
                          user_data.role === 'Investigator' ||
                          user_data.role === 'Sub_investigator' ||
                          user_data.role === 'Inv_coordinator' ||
                          (role === 'Cro_coordinator' &&
                            role === 'Data Manager')
                            ? 'Archived Subjects'
                            : 'Archived Patients'}
                        </Link>
                      </li>
                      {role === 'Super Admin' && (
                        <li>
                          <Link
                            to=""
                            onClick={(e) => {
                              handlePatientList('deactive');
                            }}
                            className={
                              typeOfPatient == 'deactive' ? 'active' : ''
                            }
                          >
                            <img
                              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRy80o55Yi7zcropiITyj_60j_ITCo5_wvfyQ&usqp=CAU"
                              alt=""
                            />
                            Deactivated Patients
                          </Link>
                        </li>
                      )}
                    </ul>
                  </>
                )}
            </div>
          </div>
          <div className="col-md-10">
            <div className="tableHeader">
              <div className="row">
                <div className="col-md-3">
                  <h5>
                    {user_data.role === 'CRO Admin' ||
                    user_data.role === 'Investigator' ||
                    user_data.role === 'Sub_investigator' ||
                    user_data.role === 'Inv_coordinator' ||
                    role === 'Cro_coordinator' ||
                    role === 'Data Manager'
                      ? 'Subjects'
                      : 'Patients'}
                  </h5>
                </div>
                <div className="col-md-9 text-end my-2">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </button>
                  &nbsp;
                  {role !== 'Reviewer' &&
                    role !== 'CRO Admin' &&
                    role !== 'Cro_coordinator' &&
                    role !== 'Data Manager' && (
                      <>
                        {typeOfPatient == 'active' && (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={(e) =>
                                handleDeactivateActivate('archive')
                              }
                            >
                              Archive Selected
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={(e) =>
                                handleDeactivateActivate('deactive')
                              }
                            >
                              Deactivate Selected
                            </button>
                            &nbsp;
                            {(role === 'Investigator' ||
                              role === 'Sub_investigator' ||
                              role === 'Inv_coordinator') && (
                              <>
                                &nbsp;
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => {
                                    setModalShow(true);
                                  }}
                                >
                                  {user_data.role === 'Investigator' ||
                                  user_data.role === 'Inv_coordinator' ||
                                  user_data.role === 'Sub_investigator'
                                    ? 'Add New Subject'
                                    : 'Add New Patient'}
                                </button>
                              </>
                            )}
                            {(role === 'Super Admin' ||
                              role === 'Clinic Admin') && (
                              <>
                                &nbsp;
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => {
                                    setClinicModalShow(true);
                                  }}
                                >
                                  Add Clinic Patient
                                </button>
                              </>
                            )}
                          </>
                        )}
                        {typeOfPatient == 'deactive' && (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={(e) =>
                                handleDeactivateActivate('active')
                              }
                            >
                              Activate Selected
                            </button>
                          </>
                        )}
                        {typeOfPatient == 'archived' && (
                          <>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={(e) =>
                                handleDeactivateActivate('unarchive')
                              }
                            >
                              Unarchive Selected
                            </button>
                          </>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
            {Loader ? (
              <div className="loader">
                <img src="https://i.gifer.com/Yb3A.gif" alt="" />
              </div>
            ) : (
              <div className="mb-3">
                <PatientTabel
                  typeOfPatient={typeOfPatient}
                  setRowSelectionModel={setRowSelectionModel}
                  rowSelectionModel={rowSelectionModel}
                  changedPatientTable={changedPatientTable}
                  setChangedPatientTable={setChangedPatientTable}
                  clinicPatient={clinicPatient}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {modalShow && (
        <>
          <AddNewPatientModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            modalShow={setModalShow}
            setCheckPatient={setCheckPatient}
            changeDateTime={changeDateTime}
            dateTime={dateTime}
            today={today}
            addPatientApi={addPatientApi}
            patientData={patientData}
            setPatientData={setPatientData}
            patientTable={true}
            drugKits={drugKits}
            setSearchDrug={setSearchDrug}
            setManualDrug={setManualDrug}
            manualDrug={manualDrug}
          />
        </>
      )}
      {clinicModalShow && (
        <>
          <ClinicAdmin
            show={clinicModalShow}
            onHide={() => setClinicModalShow(false)}
            clinicModalShow={setClinicModalShow}
            setCheckPatient={setCheckPatient}
            AddClinicPatient={AddClinicPatient}
            GetClinics={GetClinics}
            setClinics={setClinics}
            clinics={clinics}
            clinicData={clinicData}
            setClinicData={setClinicData}
            clinicPatient={clinicPatient}
          />
        </>
      )}
      {surveyModalShow && (
        <>
          <SurveyPoem
            show={surveyModalShow}
            setSurveyModalShow={setSurveyModalShow}
            onHide={() => setSurveyModalShow(false)}
          />
        </>
      )}
      {checkPatient && (
        <PopMessage
          setCheckPatient={setCheckPatient}
          addPatientApi={addPatientApi}
        ></PopMessage>
      )}
    </>
  );
};

export default NewDashboard;
