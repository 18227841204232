import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';

import NavBar from '../../components/navBar/navBar';
import PatientMenu from './PatientMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import ReactPaginate from 'react-paginate';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { __API_URL__ } from '../../SERVER_URL';
import useFetch from '../../hooks/useFetch';

const PatientActivity = () => {
  const { _id_ } = useParams();
  const token = localStorage.getItem('token');
  const { PatientData, PaginationData, handleRefresh } = useFetch(
    `patient/patient/all/activity/?PatientId=${_id_}`
  );
  const loaderContext = useContext(LoaderContext);

  const [infoData, setInfoData] = useState({});

  const handlePageClick = (event) => {
    const no = event.selected + 1;
    PaginationData(
      `/api/patient/patient/all/activity/?PatientId=${_id_}&page=${no}`
    );
  };

  const SinglePatient = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };
  useEffect(() => {
    SinglePatient();
  }, []);

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <Breadcrumbs
          B="Activity"
          F={infoData?.first_name}
          L={infoData?.last_name}
          E={infoData?.email}
          P={infoData?.phone}
          participant_id={infoData?.participant_id}
          age={infoData?.age}
          birth_date={infoData?.birth_date}
        />
        <div className="patient_table">
          <PatientMenu active="activity" data={infoData} />
          <div className="tableHeader">
            <div className="row">
              <div className="col-md-6">
                <h5>Patient Activity</h5>
              </div>
              <div className="col-md-6 text-end">
                <button
                  type="button"
                  class="btn btn-primary btn-sm m-2"
                  onClick={handleRefresh}
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
          <table>
            {loaderContext.loaderCount > 0 ? (
              <Loader />
            ) : (
              <>
                <thead>
                  <tr>
                    <th>Clinic</th>
                    <th>Description</th>
                    <th>Short Description</th>
                    <th>Activity</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {PatientData.results ? (
                    <>
                      {PatientData.results.map((data) => (
                        <tr key={data.id}>
                          <td>
                            {data.clinic}
                          </td>
                          <td>{data.description}</td>
                          <td>{data.short_description}</td>
                          <td>{data.api}</td>
                          <td>
                            {new Date(data.created_at + 'Z').toDateString()}
                            ,&nbsp;
                            {new Date(
                              data.created_at + 'Z'
                            ).toLocaleTimeString()}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </>
            )}
          </table>
          <div className="reactPagination" style={{ paddingBottom: '2px' }}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={Math.ceil(PatientData.count / 20)}
              previousLabel="previous"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientActivity;
