import React from 'react';
import Basic_Info from '../Trials/Basic_Info';
import MainAccordion from '../Accordion/accordion';

const BasicInfoAccordion = ({
  numberOfVisits,
  numberOfVisitsSetter,
  setMessage,
  setTypeOfMessage,
  setisEvent,
  isEvent,
  eventList,
  setEventList,
  setSelectedTrialType,
  AllVisitsData
}) => {
  return (
    <MainAccordion
      title="Basic Trial Information"
      data={
        <Basic_Info
          numberOfVisits={numberOfVisits}
          numberOfVisitsSetter={numberOfVisitsSetter}
          setMessage={setMessage}
          setTypeOfMessage={setTypeOfMessage}
          isEvent={isEvent}
          setisEvent={setisEvent}
          eventList={eventList}
          setEventList={setEventList}
          setSelectedTrialType={setSelectedTrialType}
          AllVisitsData={AllVisitsData}
        />
      }
      isOpen={true}
    />
  );
};
export default BasicInfoAccordion;
