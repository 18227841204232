import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { __API_URL__ } from "../../SERVER_URL";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import { LoaderContext } from "../../context/LoaderContext";

const AddTrialTypePopUp = ({
  showTrialTypeModal,
  setShowTrialTypeModal,
  blockId,
  trialTypeIds,
  setTrialTypeIds,
  AllVisitsData,
}) => {
  const token = localStorage.getItem("token");
  const [trialType, setTrialType] = useState([]);
  const loaderContext = useContext(LoaderContext);

  const getTrialTypes = () => {

    axios
      .get(`${__API_URL__}/api/photos/get_trial_types/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        res = res.data;
        setTrialType(res);
      }).finally(() => {
      });
  };

  const updateTrialTypes = () => {
    const data = {
      block_id: blockId,
      trial_type_id_list: trialTypeIds,
    };
    axios
      .post(`${__API_URL__}/api/ecrf/update_trial_type/`, data, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        toast.success(response.data.message);
        AllVisitsData();
        setShowTrialTypeModal(false);
        setTrialTypeIds([]);
      })
      .catch(() => {
        toast.error();
      });
  };

  useEffect(() => {
    getTrialTypes();
  }, []);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setTrialTypeIds(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <>
      <Modal
        size="md"
        centered
        show={showTrialTypeModal}
        onHide={() => {
          setShowTrialTypeModal(false);
          setTrialTypeIds([]);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Trial Type
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <InputLabel id="trial-type-select-label">
            {trialTypeIds.length > 1 ? "Trial Types" : "Trial Type"}
          </InputLabel>
          <Select
            labelId="trial-type-select-label"
            multiple
            value={trialTypeIds}
            onChange={handleChange}
            renderValue={(selected) =>
              selected
                .map((id) => {
                  const trial = trialType.find((t) => t.id === id);
                  return trial ? trial.title : "";
                })
                .join(", ")
            }
            fullWidth
          >
            {trialType.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                <Checkbox checked={trialTypeIds.indexOf(type.id) > -1} />
                <ListItemText primary={type.title} />
              </MenuItem>
            ))}
          </Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={updateTrialTypes}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddTrialTypePopUp;
