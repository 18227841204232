import React from 'react'
import TrialNotification from '../../Pages/Patient/TrialNotification'
import MainAccordion from '../Accordion/accordion'

const TrialNotificationAccordion = ({trialId, setMessage, setTypeOfMessage}) => {
  return (
    <MainAccordion
    title="Automatic Notifications"
    data={
        <TrialNotification
        trialId={trialId} 
        setMessage={setMessage}
        setTypeOfMessage={setTypeOfMessage}
        />}
    />
  )
}
export default TrialNotificationAccordion