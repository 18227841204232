import Modal from "react-bootstrap/Modal";

const AddNewTrial = (props) => {
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        props.setTrialInfo((trial) => {
            return {
                ...trial,
                [name]: value
            }
        })
    }
    
    return (
        <>
            {
                <Modal aria-labelledby="contained-modal-title-vcenter" size="md" centered show backdrop="static" onHide={() => props.handleModalHide()}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {props.isEdit ? "Edit Trial" : "Add Trial"}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        <div>
                            <label>Custom Id</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="ID"
                                onChange={(e) => handleFieldChange(e)}
                                name="trial_custom_id"
                                value={props.trialInfo.trial_custom_id}
                            ></input>
                            <label>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                onChange={(e) => handleFieldChange(e)}
                                name="name"
                                value={props.trialInfo.name}
                            ></input>
                            <label>Status</label>
                            <select className="form-control"
                                onChange={(e) => handleFieldChange(e)}
                                name="status"
                                value={props.trialInfo.status}>
                                <option value="" disabled>Select</option>
                                <option value="Active">Active</option>
                                <option value="Completed">Completed</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="New">New</option>
                            </select>
                            <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => props.handleAddTrial()}
                            >
                                {props.isEdit ? "Update" : "Add"}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}
export default AddNewTrial;