import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const LockedAccountModal = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          onClose();
        }
      }}
      aria-labelledby="locked-account-title"
      aria-describedby="locked-account-description"
      sx={{
        backdropFilter: "blur(5px)", 
        backgroundColor: "rgba(0, 0, 0, 0.7)", 
      }}
    >
      <Box
        sx={{
          position: "fixed",  
          top: "50%",  
          left: "50%",  
          transform: "translate(-50%, -50%)", 
          width: "90%", 
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 6, 
          borderRadius: 3,
          textAlign: "center",
        }}
      >
        <Typography
          id="locked-account-title"
          variant="h3"
          component="h2"
          sx={{
            fontWeight: "bold",
            color: "error.main", 
            mb: 3,
            fontSize: "2rem",
          }}
        >
          Account Locked
        </Typography>
        <Typography
          id="locked-account-description"
          sx={{
            mb: 5,
            fontSize: "1.2rem",
            color: "text.secondary",
            lineHeight: 1.8,
          }}
        >
          Your account has been locked because you have exceeded the allowed maximum number of unsuccessful login attempts. Please reach out
          to our support team at{" "}
          <Typography
            component="span"
            sx={{
              fontWeight: "bold",
              color: "error.main",  
            }}
          >
            support@appiell.ai
          </Typography>{" "}
          for assistance. We apologize for any inconvenience caused and are here
          to help.
        </Typography>
        <Button
          sx={{
            mt: 3,
            px: 6,
            py: 1.5,
            fontSize: "1.1rem",
            color: "white",  
            backgroundColor: "error.main", 
            borderColor: "error.main", 
            "&:hover": {
              backgroundColor: "error.dark",
              borderColor: "error.dark",
            },
          }}
          variant="contained"
          onClick={onClose}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default LockedAccountModal;