import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { ApiManager } from '../../servicemanager/apimanager';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import axios from 'axios';
import 'chart.js/auto';

import FilterPatientPopUp from '../../components/PopUp/FilterPatientPopUp';
import NavBar from '../../components/navBar/navBar';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { __API_URL__ } from '../../SERVER_URL';
import useFetch from '../../hooks/useFetch';

const Summary_Trial = () => {
  const navigate = useNavigate();
  const { handleRefresh } = useFetch();
  const { _id_ } = useParams();
  const token = localStorage.getItem('token');
  const loaderContext = useContext(LoaderContext);

  const [summaryTrial, setSummaryTrial] = useState({});
  const [getTrial, setGetTrial] = useState({});
  const [filterPatientPopUp, setFilterPatientPopUp] = useState(false);
  const [filterPatient, setFilterPatient] = useState({});

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const handleConfiguration = () => {
    navigate('/clinic_trials/' + _id_);
  };

  const handleShowFilterPatientPopUp = (data) => {
    setFilterPatientPopUp(true);
    setFilterPatient(data);
  };

  const ClickableTableCell = styled('td')(({ theme, clickable }) => ({
    height: 'auto',
    textAlign: 'center',
    cursor: clickable ? 'pointer' : 'default',
    ...(clickable && {
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        color: theme.palette.text.primary,
      },
    }),
  }));

  const getSummary = async () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    try {
      const response = await ApiManager.get(
        `${__API_URL__}/api/photos/get_summary_trial/${_id_}/`,
        token
      );
      setSummaryTrial(response.data);
    } catch (error) {
      console.log(error);
      return [];
    } finally {
      loaderContext.setLoaderCount((prev) => prev - 1);
    }
  };

  const getTrialApi = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/photos/get_trial/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        res = res.data;
        setGetTrial(res);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  useEffect(() => {
    getSummary();
    getTrialApi();
  }, []);

  return (
    <>
      {loaderContext.loaderCount > 0 ? (
        <Loader />
      ) : (
        <>
          <NavBar active="trials" />
          <div className="container-fluid">
            <div className="row patient_table">
              <div className="col-md-12">
                <div className="tableHeader">
                  <div className="row d-flex align-items-end">
                    <div className="col-md-6">
                      <h5>Summary Trial</h5>
                    </div>
                    <div className="col-md-6 text-end d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm  mx-2  mt-2"
                        onClick={() => handleRefresh()}
                      >
                        Refresh
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-primary btn-sm  mx-2  mt-2"
                        onClick={handleConfiguration}
                      >
                        Trial Configuration
                      </button>
                      &nbsp;
                    </div>
                  </div>
                </div>
                <Box sx={{ flexGrow: 1, marginTop: '30px' }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    <Grid item xs={12} md={6}>
                      <Item>
                        {' '}
                        Trial Name: {summaryTrial?.data?.name || '-'}
                      </Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Item>
                        {' '}
                        Trial Status: {summaryTrial?.data?.status || '-'}
                      </Item>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Item>
                        {' '}
                        Trial Type:{' '}
                        {summaryTrial?.data?.trial_type?.title || '-'}
                      </Item>
                    </Grid>
                  </Grid>
                </Box>
                <hr />
                {getTrial?.is_setting ? (
                  <Grid
                    container
                    spacing={{ xs: 4, md: 5 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    style={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {summaryTrial?.data?.investigators?.map((Inv, index) => (
                      <Grid key={index} item xs={12} md={6}>
                        <div
                          className="table-responsive"
                          style={{
                            overflow: 'hidden',
                            maxHeight: '100%',
                            maxWidth: '100%',
                          }}
                        >
                          <table
                            style={{
                              fontSize: '8px',
                              padding: '2px',
                              height: 'auto',
                            }}
                          >
                            <thead>
                              <tr>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.completed_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    maxWidth: '50px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Fully
                                  <br /> Completed
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict
                                        ?.partially_completed_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    maxWidth: '100px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Fully Completed /<br />
                                  Skipped
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.active_patients
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    maxWidth: '50px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Active
                                  <br />
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.skipped_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    maxWidth: '50px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Active /<br />
                                  Skipped
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.exit_patients
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    maxWidth: '50px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Exited
                                  <br />
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict
                                        ?.not_started_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    maxWidth: '50px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Enrolled /<br />
                                  Not Started
                                </ClickableTableCell>
                                <th
                                  style={{
                                    height: 'auto',
                                    maxWidth: '50px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Not <br />
                                  Enrolled
                                </th>
                                <th
                                  style={{
                                    height: 'auto',
                                    maxWidth: '50px',
                                    textAlign: 'center',
                                  }}
                                >
                                  Target <br />
                                  Enrollment
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.completed_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv?.patient_count_dict?.completed_patient
                                    .length || '-'}
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict
                                        ?.partially_completed_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv?.patient_count_dict
                                    ?.partially_completed_patient.length || '-'}
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.active_patients
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv?.patient_count_dict?.active_patients
                                    .length || '-'}
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.skipped_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv?.patient_count_dict?.skipped_patient
                                    .length || '-'}
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict?.exit_patients
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv?.patient_count_dict?.exit_patients
                                    .length || '-'}
                                </ClickableTableCell>
                                <ClickableTableCell
                                  className="clickable"
                                  clickable
                                  onClick={() =>
                                    handleShowFilterPatientPopUp(
                                      Inv?.patient_count_dict
                                        ?.not_started_patient
                                    )
                                  }
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv?.patient_count_dict?.not_started_patient
                                    .length || '-'}
                                </ClickableTableCell>
                                <td
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv.remaining_patient || '-'}
                                </td>
                                <td
                                  style={{
                                    height: 'auto',
                                    textAlign: 'center',
                                  }}
                                >
                                  {Inv.max_patient || '-'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <Item>
                          Site {Inv?.site_number}
                          <Item>
                            <Doughnut
                              data={{
                                labels: [
                                  'Fully Completed',
                                  'Fully Completed/Skipped',
                                  'Active',
                                  'Active/Skipped',
                                  'Exited',
                                  'Enrolled/Not Started',
                                  'Not Enrolled',
                                ],

                                datasets: [
                                  {
                                    backgroundColor: [
                                      '#2ecc71',
                                      '#3498db',
                                      '#ff1493',
                                      '#f1c40f',
                                      '#e74c3c',
                                      '#dda0dd',
                                      '#dcdcdc',
                                    ],
                                    data: [
                                      Inv?.patient_count_dict?.completed_patient
                                        .length,
                                      Inv?.patient_count_dict
                                        ?.partially_completed_patient.length,
                                      Inv?.patient_count_dict?.active_patients
                                        .length,
                                      Inv?.patient_count_dict?.skipped_patient
                                        .length,
                                      Inv?.patient_count_dict?.exit_patients
                                        .length,
                                      Inv?.patient_count_dict
                                        ?.not_started_patient.length,
                                      Inv?.remaining_patient,
                                    ],
                                  },
                                ],
                              }}
                              options={{
                                cutout: 140,
                                radius: '40%',
                                rotation: 180,
                              }}
                            />
                            Max Patients {Inv?.max_patient || '-'}
                          </Item>
                        </Item>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <p className="col-md-2 mx-auto mt-5">
                    'There is Not Site ID for this Trial'
                  </p>
                )}
              </div>
              {filterPatientPopUp && (
                <FilterPatientPopUp
                  setFilterPatientPopUp={setFilterPatientPopUp}
                  getSummary={getSummary}
                  summaryTrial={summaryTrial}
                  filterPatient={filterPatient}
                ></FilterPatientPopUp>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Summary_Trial;
