import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

import NavBar from '../../components/navBar/navBar';
import PatientMenu from '../Patient/PatientMenu';
import Breadcrumbs from '../../components/breadcrumbs';
import ChangeLogTable from './ChangeLogTable';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { __API_URL__ } from '../../SERVER_URL';

const ChangeLogs = () => {
  const convertFirstLetterToCapital = (str) => {
    const newStr = str.charAt(0).toUpperCase() + str.slice(1);
    return newStr;
  };

  const { _id_ } = useParams();
  const token = localStorage.getItem('token');
  const user_data = JSON.parse(localStorage.getItem('user_data'));
  const role = user_data.role;
  const loaderContext = useContext(LoaderContext);

  const [trialId, setTrialId] = useState(0);
  const [changeLog, setChangeLog] = useState();
  const [pageItemCount, setPageItemCount] = useState(10);
  const [infoData, setInfoData] = useState({});
  const [largeModalShow, setLargeModalShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  const trialDetailApi = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(`${__API_URL__}/api/photos/get_trial_for_patient/${_id_}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        res = res.data;
        setTrialId(res.trial.id);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const handlePageClick = (event) => {
    const no = event.selected + 1;
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/ecrf/get_change_logs?page=${no}&trial=${trialId}&patient=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeLog(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const SinglePatient = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const handleExportCSV = () => {
    window.open(changeLog?.file_url);
  };

  const getChangeLogs = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/ecrf/get_change_logs/?page=1&trial=${trialId}&patient=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setChangeLog(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  useEffect(() => {
    trialDetailApi();
    SinglePatient();
  }, []);

  useEffect(() => {
    if (trialId != 0) {
      getChangeLogs();
    }
  }, [trialId]);

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs
            B="eCRF Change Logs"
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
          ></Breadcrumbs>
          <PatientMenu active="changeLogs" data={infoData} />
          {loaderContext.loaderCount > 0 ? (
            <Loader />
          ) : (
            <div className="tableHeader">
              <div className="row">
                <div className="row border" key="" style={{ padding: '1em' }}>
                  <div className="tableHeader">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12 text-end">
                            <button
                              onClick={handleExportCSV}
                              target="_blank"
                              style={{
                                padding: '0.25rem 0.5rem',
                                fontSize: '0.75rem',
                              }} // Custom inline style for smaller button
                              download
                              className="btn btn-primary btn-sm"
                            >
                              Export as CSV
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              style={{
                                padding: '0.25rem 0.5rem',
                                fontSize: '0.75rem',
                              }}
                              onClick={() => setLargeModalShow(true)}
                            >
                              Full Screen View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="borderless table-wrapper-scroll-y my-custom-scrollbar">
                    {!largeModalShow ? (
                      <>
                        <ChangeLogTable
                          changeLog={changeLog}
                          convertFirstLetterToCapital={
                            convertFirstLetterToCapital
                          }
                          role={role}
                          handlePageClick={handlePageClick}
                          pageItemCount={pageItemCount}
                        />
                      </>
                    ) : (
                      <>
                        <Modal
                          size="xxl"
                          show={largeModalShow}
                          fullscreen={fullscreen}
                          onHide={() => setLargeModalShow(false)}
                          backdrop="static"
                        >
                          <Modal.Header closeButton></Modal.Header>
                          <Modal.Body>
                            <ChangeLogTable
                              changeLog={changeLog}
                              convertFirstLetterToCapital={
                                convertFirstLetterToCapital
                              }
                              role={role}
                              handlePageClick={handlePageClick}
                              pageItemCount={pageItemCount}
                            />
                          </Modal.Body>
                        </Modal>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChangeLogs;
