import { useState } from 'react';
import axios from 'axios';

import { __API_URL__ } from '../SERVER_URL';

const useCalendar = () => {
  const [appointmentData, setAppointmentData] = useState();
  const [practitioners, setPractitioners] = useState();
  const token = localStorage.getItem('token');

  const getAppointment = () => {
    axios
      .get(`${__API_URL__}/api/appointments/appointment_list/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setAppointmentData(res.data.data);
      });
  };

  const getPractitioners = () => {
    axios
      .get(`${__API_URL__}/api/appointments/all_practitioners`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setPractitioners(res.data);
      })
      .catch((err) => console.log(err));
  };

  const updateAppointment = (vl) => {
    axios
      .patch(
        `${__API_URL__}/api/appointments/update_appointment/${vl.id}`,
        vl.values,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return {
    appointmentData,
    practitioners,
    getAppointment,
    getPractitioners,
    updateAppointment,
  };
};

export default useCalendar;
