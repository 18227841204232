import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { __API_URL__ } from "../../SERVER_URL";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { Converter } from "showdown";
import { toast, ToastContainer } from "react-toastify";
import Modal from "react-bootstrap/Modal";
function EcrfBlocks({
  visit_number,
  trialId,
  setBlockId,
  selectedSessionId,
  selectedBlock,
  patient_id,
  setMessage,
  setTypeOfMessage,
  photo_session,
  newForm,
  AllVisitsData,
  trialDetailApi,
  fromQueryTable,
  showEcrfBlock,
  setShowEcrfBlock,
  isOpen,
}) {
  const token = localStorage.getItem("token");
  const user_data = JSON.parse(localStorage.getItem("user_data"));
  const role = user_data.role;
  const [formData, setFormData] = useState({});
  const [formValues, setFormValues] = useState({});
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(false);
  const [surveyJson, setSurveyJson] = useState({});
  const [surveyId, setSurveyId] = useState(0);
  const [survey, setSurvey] = useState(new Model({}));
  const [answerJson, setAnswerJson] = useState({});
  const [largeModalShow, setLargeModalShow] = useState(false); // New state for large modal
  const [fullscreen, setFullscreen] = useState(true);
  const [statusObject, setStatusObject] = useState({});

  useEffect(() => {
    survey.data = answerJson;
  }, [answerJson]);

  useEffect(() => {
    if (survey) {
      survey.onValueChanging.add((x, options) => {
        if (options.question.name.toLowerCase().includes("weight")) {
          let heightValue;
          let bmiKeyName;
          x.pages[0].elements.map((key) => {
            if (key.name.toLowerCase().includes("height")) {
              heightValue = x.data[key.name];
            }

            if (key.name.toLowerCase().includes("bmi")) {
              bmiKeyName = key.name;
            }
          });

          let bmi;
          if (heightValue !== undefined && heightValue != 0) {
            bmi = (options.value / (heightValue * heightValue)) * 10000;
          }

          const newJson = {};
          Object.assign(newJson, x.data);

          if (bmi) {
            newJson[bmiKeyName] = bmi.toFixed(1);
          }

          x.data = newJson;
        }

        if (options.question.name.toLowerCase().includes("height")) {
          let weightValue;
          let bmiKeyName;
          x.pages[0].elements.map((key) => {
            if (key.name.toLowerCase().includes("weight")) {
              weightValue = x.data[key.name];
            }

            if (key.name.toLowerCase().includes("bmi")) {
              bmiKeyName = key.name;
            }
          });

          let bmi;
          if (weightValue !== undefined) {
            bmi = (weightValue / (options.value * options.value)) * 10000;
          }

          const newJson = {};
          Object.assign(newJson, x.data);

          if (bmi) {
            newJson[bmiKeyName] = bmi.toFixed(1);
          }

          x.data = newJson;
        }
        // Temperature to one decimal place
        if (options.question.name.toLowerCase().includes("temperature")) {
          let temperatureValue = options.value;
          const newObj = Object.assign({}, x.data[options.question.name]);
          const finalObj = Object.assign(x.data, newObj);
          x.data = finalObj;
          if (typeof temperatureValue === "number") {
            options.value = temperatureValue.toFixed(1);
          }
        }
        // Height to one decimal place
        if (options.question.name.toLowerCase().includes("height")) {
          let heightValue = options.value;
          const newObj = Object.assign({}, x.data[options.question.name]);
          const finalObj = Object.assign(x.data, newObj);
          x.data = finalObj;
          if (typeof heightValue === "number") {
            options.value = heightValue.toFixed(1);
          }
        }
        // Assigned Dose to one decimal place
        if (options.question.name.toLowerCase().includes("assigned dose")) {
          let heightValue = options.value;
          const newObj = Object.assign({}, x.data[options.question.name]);
          const finalObj = Object.assign(x.data, newObj);
          x.data = finalObj;
          if (typeof heightValue === "number") {
            options.value = heightValue.toFixed(2);
          }
        }
        // Delivered Dose to one decimal place
        if (options.question.name.toLowerCase().includes("delivered dose")) {
          let heightValue = options.value;
          const newObj = Object.assign({}, x.data[options.question.name]);
          const finalObj = Object.assign(x.data, newObj);
          x.data = finalObj;
          if (typeof heightValue === "number") {
            options.value = heightValue.toFixed(2);
          }
        }
        // Weight to one decimal place
        if (options.question.name.toLowerCase().includes("weight")) {
          let weightValue = options.value;
          const newObj = Object.assign({}, x.data[options.question.name]);
          const finalObj = Object.assign(x.data, newObj);
          x.data = finalObj;
          if (typeof weightValue === "number") {
            options.value = weightValue.toFixed(1);
          }
        }
      });

      survey.onValidatedErrorsOnCurrentPage.add((x, options) => {
        const newJson = {};
        Object.assign(newJson, x.data);
        let hasErrors = false;
        let constraintError = false;
        let hasEmptyField = false;


        options.questions.map((q) => {
          if (q.value > q.jsonObj.max || q.value < q.jsonObj.min) {
            constraintError = true;
          }

          if (!q.value)
            hasEmptyField = true;

          hasErrors = true;
          newJson[q.name] = q.value;
        });

        let possibleIncorrectData = checkForInclusionExclusion(x).length > 0 || CheckDynamicValue(x).length > 0;

        if (patient_id && hasErrors) {
          saveSurveyAnswer(newJson, hasEmptyField || possibleIncorrectData, constraintError);
          setLargeModalShow(false);
          toast.info(
            hasEmptyField ? "Survey saved successfully with empty fields." : 
            (constraintError ? "Survey saved successfully with some out of bound value." : "Survey saved successfully with validation errors.")
          );
        } else if (patient_id) {
          saveSurveyAnswer(newJson, possibleIncorrectData, constraintError);
          setLargeModalShow(false);
          toast.success("Survey saved successfully.");
        }
      });

      survey.onComplete.add(function (result) {
        if (!patient_id) {
          survey.clear();
          survey.data = {};
        }
      });
      survey.showCompletedPage = false;
      survey.completedHtml = "<span></span>";

      if (
        ["CRO Admin", "Cro_coordinator", "Data Manager"].includes(role) &&
        patient_id
      ) {
        survey.mode = "display";
      } else if (isOpen === false) {
        survey.mode = "display";
      }

      // Instantiate Showdown
      const converter = new Converter();
      survey.onTextMarkdown.add(function (survey, options) {
        // Convert Markdown to HTML
        let str = converter.makeHtml(options.text);
        // Remove root paragraphs <p></p>
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        // Set HTML markup to render
        options.html = str;
      });

      const checkForInclusionExclusion = (result) => {
        let noAnswers = [];
        if (
          [
            "INCLUSION CRITERIA - Screening Visit (Visit 1)",
            "EXCLUSION CRITERIA - Screening Visit (Visit 1)",
          ].includes(result.title)
        ) {
          for (const [key, value] of Object.entries(result.data)) {
            if (
              result.title === "EXCLUSION CRITERIA - Screening Visit (Visit 1)"
            ) {
              if (value === true) {
                noAnswers.push(key);
              }
            } else {
              if (value === false) {
                noAnswers.push(key);
              }
            }
          }
          if (noAnswers.length > 0) {
            toast.info("Please exclude this patient");
          }
        }
        return noAnswers;
      }
      
      const CheckDynamicValue = (result) => {
        let noAnswers = [];
        if (["INCLUSION CRITERIA", "EXCLUSION CRITERIA"].includes(result.title)) {
          for (const [key, value] of Object.entries(result.data)) {
            if (Array.isArray(value)) {
              value.forEach((val) => {
                if (result.title === "EXCLUSION CRITERIA" && val === 'Yes') {
                  noAnswers.push(key);
                } else if (result.title === "INCLUSION CRITERIA" && val === 'No') {
                  noAnswers.push(key);
                }
              });
            } 
          }
          if (noAnswers.length > 0) {
            toast.info("Please exclude this patient");
          }
        }
        return noAnswers;
      };
      
    }
  }, [survey]);

  useEffect(() => {
    getSurvey();
  }, [selectedBlock]);

  useEffect(() => {
    if (surveyId !== 0 && patient_id) getSurveyAnswer();
  }, [surveyId]);

  useEffect(() => {
    if (patient_id === undefined) {
      setDisable(true);
    }
  }, []);

  useEffect(() => {
    if (statusObject?.dm_freeze_bool === true) {
      survey.mode = "display";
    }
  }, [statusObject]);

  const getSurvey = () => {
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_new_ecrf/?surveyName=${selectedBlock}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setSurveyJson(res.data.data.surveyJson);
        setSurveyId(res.data.data.id);
        if (setBlockId) setBlockId(res.data.data.id);
        const localSurvey = new Model(res.data.data.surveyJson);
        setSurvey(localSurvey);
      })
      .catch((err) => {
        // toast.error("Something Wrong!");
      });
  };

  const saveSurveyAnswer = (answerJson, possibleIncorrectData, hasConstraintsError) => {
    axios
      .post(
        `${__API_URL__}/api/ecrf/save_new_ecrf_answers/`,
        {
          ecrf_model: surveyId,
          answerJson: answerJson,
          visit_number: visit_number,
          trialId: trialId,
          patient_id: patient_id,
          sessionId: selectedSessionId,
          possible_incorrect_data: possibleIncorrectData,
          has_constraint_error: hasConstraintsError,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        survey.clear();
        setAnswerJson(res.data.data.answerJson);
        AllVisitsData(false);
        trialDetailApi();
      })
      .catch((err) => {
        // toast.error("Something Wrong!");
      });
  };

  const getSurveyAnswer = () => {
    axios
      .get(
        `${__API_URL__}/api/ecrf/get_new_ecrf_answers/?ecrfModelId=${surveyId}&visitNumber=${visit_number}&trialId=${trialId}&patientId=${patient_id}&sessionId=${selectedSessionId}&`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setAnswerJson(res.data.data.answerJson);
        setStatusObject(res.data.data.status_object);
      })
      .catch((err) => {
        // toast.error("Something Wrong!");
      });
  };

  return (
    <>
      <ToastContainer />
      {loader ? (
        <div className="loader">
          <img src="https://i.gifer.com/Yb3A.gif" alt="" />
        </div>
      ) : !fromQueryTable ? (
        photo_session || patient_id == undefined ? (
          <>
            <div className="col-md-12 text-end mx-2  mt-2">
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => setLargeModalShow(true)}
              >
                Full Screen
              </button>
            </div>
            <div className="my-1">
              <div className="row d-flex align-items-end">
                <div className="col-md-6">
                  <h5>{formData?.name}</h5>
                </div>

                {((patient_id != undefined && role === "Investigator") ||
                  role === "Inv_coordinator") && <></>}
              </div>
            </div>

            {!largeModalShow ? (
              <>
                <Survey model={survey} />
              </>
            ) : (
              <>
                <Modal
                  size="xxl"
                  show={largeModalShow}
                  fullscreen={fullscreen}
                  onHide={() => setLargeModalShow(false)}
                  backdrop="static"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Survey model={survey} />
                  </Modal.Body>
                </Modal>
              </>
            )}
          </>
        ) : (
          "Visit Not Started"
        )
      ) : (
        <>
          <Modal
            size="xxl"
            show={showEcrfBlock}
            fullscreen={fullscreen}
            onHide={() => setShowEcrfBlock(false)}
            backdrop="static"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Survey model={survey} />
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
}

export default EcrfBlocks;
