import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

import NavBar from '../../components/navBar/navBar';
import Breadcrumbs from '../../components/breadcrumbs';
import PatientMenu from '../../Pages/Patient/PatientMenu';
import QueryChangeLogTable from './QueryChangeLogTable';
import Loader from '../../components/Loader';
import { LoaderContext } from '../../context/LoaderContext';
import { __API_URL__ } from '../../SERVER_URL';

const QueryChangeLogs = () => {
  const token = localStorage.getItem('token');
  const { _id_ } = useParams();
  const loaderContext = useContext(LoaderContext);

  const [infoData, setInfoData] = useState({});
  const [largeModalShow, setLargeModalShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [queryLogs, setQueryLogs] = useState([]);
  const [pageItemCount, setPageItemCount] = useState(10);

  const SinglePatient = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/patient/patient/singlepatient/?patientId=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setInfoData(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const handlePageClick = (event) => {
    loaderContext.setLoaderCount((prev) => prev + 1);
    const no = event.selected + 1;

    axios
      .get(
        `${__API_URL__}/api/ecrf/get_query_change_logs?page=${no}&patient_id=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setQueryLogs(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  const getQueryLogs = () => {
    loaderContext.setLoaderCount((prev) => prev + 1);

    axios
      .get(
        `${__API_URL__}/api/ecrf/get_query_change_logs/?patient_id=${_id_}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        setQueryLogs(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loaderContext.setLoaderCount((prev) => prev - 1);
      });
  };

  useEffect(() => {
    getQueryLogs();
    SinglePatient();
  }, []);

  const handleExportCSV = () => {
    window.open(queryLogs?.file_url);
  };

  return (
    <>
      <NavBar active="patient" />
      <div className="container-fluid">
        <div className="patient_table patient_photos">
          <Breadcrumbs
            B="eCRF Change Logs"
            F={infoData?.first_name}
            L={infoData?.last_name}
            E={infoData?.email}
            P={infoData?.phone}
            participant_id={infoData?.participant_id}
            age={infoData?.age}
            birth_date={infoData?.birth_date}
          ></Breadcrumbs>
          <PatientMenu active="QueryChangeLogs" data={infoData} />
          {loaderContext.loaderCount > 0 ? (
            <Loader />
          ) : (
            <div className="tableHeader">
              <div className="row">
                <div className="row border" key="" style={{ padding: '1em' }}>
                  <div className="tableHeader">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12 text-end">
                            <button
                              onClick={handleExportCSV}
                              target="_blank"
                              style={{
                                padding: '0.25rem 0.5rem',
                                fontSize: '0.75rem',
                              }}
                              download
                              className="btn btn-primary btn-sm"
                            >
                              Export as Excel
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              style={{
                                padding: '0.25rem 0.5rem',
                                fontSize: '0.75rem',
                              }}
                              onClick={() => setLargeModalShow(true)}
                            >
                              Full Screen View
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="borderless table-wrapper-scroll-y my-custom-scrollbar">
                    {!largeModalShow ? (
                      <>
                        <QueryChangeLogTable
                          queryLogs={queryLogs}
                          handlePageClick={handlePageClick}
                          pageItemCount={pageItemCount}
                        />
                      </>
                    ) : (
                      <>
                        <Modal
                          size="xxl"
                          show={largeModalShow}
                          fullscreen={fullscreen}
                          onHide={() => setLargeModalShow(false)}
                          backdrop="static"
                        >
                          <Modal.Header closeButton></Modal.Header>
                          <Modal.Body>
                            <QueryChangeLogTable
                              queryLogs={queryLogs}
                              handlePageClick={handlePageClick}
                              pageItemCount={pageItemCount}
                            />
                          </Modal.Body>
                        </Modal>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default QueryChangeLogs;
